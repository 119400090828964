export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string?.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getMonthDayYear = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  return { year, month, day };
};

export const SETTINGS = {
  PROFILE: "Profile",
  NOTIFICATIONS: "Notifications",
  AUTHENTICATION: "Authentication",
  BILLING: "Billing",
};

export const settingNavItems = [
  { label: SETTINGS.PROFILE },
  { label: SETTINGS.NOTIFICATIONS },
  { label: SETTINGS.AUTHENTICATION },
  { label: SETTINGS.BILLING }
]
