import toast from "react-hot-toast";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  deleteNetworkDevicesServ,
  getAllNetworkLocationsServ,
  getNetworkDevicesServ,
  getNetworkDeviceTokenServ,
  getSingleNetworkDevicesServ,
  getSingleNetworkLocationServ,
  initializeReScanServ,
  saveNetworkCredentialsServ,
} from "../services/networkDevice.service";
import { networkDeviceActions } from "../slice/networkDevice.slice";
import {
  DELETE_NETWORK,
  GET_ALL_NETWORK_LOCATIONS,
  GET_NETWORK_DEVICE_TOKEN,
  GET_NETWORKS_DEVICES,
  GET_SINGLE_NETWORK_LOCATION,
  GET_SINGLE_NETWORKS_DEVICES,
  INITIALIZE_RESCAN,
  SAVE_NETWORK_CREDENTIALS
} from "../types/networkDevice.types";

export function* getNetworkDeviceToken(action) {
  try {
    const response = yield getNetworkDeviceTokenServ(action.payload);
    if (response) {
      const networkDevices = yield select(
        networkDeviceActions.selectors.getNetworkDevices
      );
      yield put(
        networkDeviceActions.actions.setNetworkDeviceToken(response?.data)
      );

      yield put(
        networkDeviceActions.actions.setNetworkDevices([
          response?.data?.createdNetwork,
          ...networkDevices,
        ])
      );
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* getNetworkDevices(action) {
  try {
    const response = yield getNetworkDevicesServ(action.payload);
    if (response) {
      yield put(networkDeviceActions.actions.setNetworkDevices(response?.data));
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* getSingleNetworkDevice(action) {
  try {
    const response = yield getSingleNetworkDevicesServ(action.payload);
    if (response) {
      yield put(
        networkDeviceActions.actions.setSelectedNetwork(response?.data)
      );
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* getAllNetworkLocations(action) {
  try {
    const response = yield getAllNetworkLocationsServ(action.payload);
    if (response) {
      yield put(
        networkDeviceActions.actions.setAllDevicesLocation(response?.data)
      );
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* getSingleNetworkLocationSaga(action) {
  try {
    const response = yield getSingleNetworkLocationServ(action.payload);
    if (response) {
      yield put(
        networkDeviceActions.actions.setCurrentDeviceLocation(response?.data)
      );
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* deleteNetwork(action) {
  try {
    // Use toast.promise to handle loading, success, and error states
    const response = yield call(() =>
      toast.promise(
        deleteNetworkDevicesServ(action?.payload),
        {
          loading: 'Deleting networks...',
          success: 'Networks deleted successfully',
          error: 'Network deletion failed',
        }
      )
    );

    if (response) {
      const devices = yield select(networkDeviceActions.selectors.getNetworkDevices);

      const newDevices = devices.filter(device => !action.payload.networkIds.includes(device._id));

      yield put(networkDeviceActions.actions.setNetworkDevices(newDevices));
    }
  } catch (error) {
    console.log("Error deleting network:", error);
  }
}


export function* saveNetworkCredentials(action) {
  try {
    const resp = yield saveNetworkCredentialsServ(action?.payload);
    if (resp) {
      toast.success("Credentials Saved Successfully")
    }
  } catch (error) {
    console.log("err", error);
  }
}

export function* initializeReScan(action) {
  try {
    const resp = yield initializeReScanServ(action?.payload);
    if (resp) {
      const networks = yield select(networkDeviceActions.selectors.getNetworkDevices);

      const updatedNetwork = [...networks];

      const index = updatedNetwork.findIndex((net) => net?._id === resp?.data?._id);

      if (index !== -1) {
        updatedNetwork[index] = {
          ...updatedNetwork[index],
          status: resp?.data?.status,
        };
      }

      yield put(networkDeviceActions.actions.setNetworkDevices(updatedNetwork));
      toast.success("Network Re-Scan Initialized");
    }

  } catch (error) {
    console.log("err", error?.response?.data?.message);
    toast.error(error?.response?.data?.message || "Something Went Wrong")
  }
}

export function* watchNetworkDeviceAsync() {
  yield takeEvery(GET_NETWORK_DEVICE_TOKEN, getNetworkDeviceToken);
  yield takeEvery(GET_NETWORKS_DEVICES, getNetworkDevices);
  yield takeEvery(GET_SINGLE_NETWORKS_DEVICES, getSingleNetworkDevice);
  yield takeEvery(GET_ALL_NETWORK_LOCATIONS, getAllNetworkLocations);
  yield takeEvery(GET_SINGLE_NETWORK_LOCATION, getSingleNetworkLocationSaga);
  yield takeEvery(DELETE_NETWORK, deleteNetwork);
  yield takeEvery(SAVE_NETWORK_CREDENTIALS, saveNetworkCredentials);
  yield takeEvery(INITIALIZE_RESCAN, initializeReScan);
}
