import { Grid } from "@mui/material";
import React, { memo } from "react";
import MyCard from "../../shared/Card/Card";
import text from "../../shared/css/text.module.scss";
import PieChart from "../Device/PieChart";
import DetailChartItem from "./DetailChartItem";
import styles from "./singleDashboard.module.scss";
const DetailChartCard = ({
  details,
  title,
  title2 = "Results",
  chartSeries,
  totalValue,
  totalText = "Total",
  left = "35%",
  graphColors,
  isLeft = true,
}) => {

  const tempHandleClick = (data) => {
  }
  return (
    <MyCard padding={2}>
      <Grid container>
        {isLeft ? (
          <Grid item xs={4}>
            <p className={text?.heading2}>{title}</p>
            <div className={styles.detailContainer}>
              {details?.map((item, idx) => (
                <DetailChartItem
                  key={idx}
                  color={item?.color}
                  title={item?.title}
                  value={item?.value}
                />
              ))}
            </div>
          </Grid>
        ) : null}
        <Grid
          item
          xs={7}
          container
          flexDirection={"column"}
          alignItems={"center"}
        >
          <p className={`${text?.heading2} ${text.textCenter}`}>{title2}</p>
          <div className={styles.detailContainer}>
            <PieChart
              chartData={chartSeries}
              width={190}
              height={240}
              graphColors={graphColors}
              totalValue={totalValue}
              radius={120}
              left={left}
              showLabels={false}
              totalText={totalText}
              handleClick={tempHandleClick}
            />
          </div>
        </Grid>
        {!isLeft ? (
          <Grid item xs={4}>
            <p className={text?.heading2}>{title}</p>
            <div className={styles.detailContainer1}>
              {details?.map((item, idx) => (
                <DetailChartItem
                  key={idx}
                  color={item?.color}
                  title={item?.title}
                  value={item?.value}
                />
              ))}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </MyCard>
  );
};

export default memo(DetailChartCard);
