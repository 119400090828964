import { KeyboardArrowDown } from "@mui/icons-material";
import Logout from "@mui/icons-material/Logout";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import text from "../../css/text.module.scss";
import styles from "../navbar.module.scss";
import { capitalizeFirstLetter } from "../../../utils/accounts";
import { USER_LOGOUT } from "../../../../redux/types/auth.types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ProfileMenu({ user, showDropDown = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (showDropDown) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: USER_LOGOUT });
  };

  const handleMyAccount = () => {
    navigate("/account");
  };

  return (
    <React.Fragment>
      <Box onClick={handleClick} className={styles.profileButton}>
        <Tooltip title="Profile settings">
          <IconButton
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disabled={!showDropDown}
          >
            <Avatar
              src={user?.profileImage}
              alt={`profile img`}
              className={styles.profileAvatar}
            />
          </IconButton>
        </Tooltip>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography textAlign={"start"} maxWidth={70} textOverflow={'ellipsis'} overflow={'clip'} sx={{
            whiteSpace: 'nowrap'
          }} className={text.heading2}>{user?.username}</Typography>
          <Typography className={text.info3}>{capitalizeFirstLetter(user?.role)}</Typography>
        </Box>
        {showDropDown && (
          <Box height={"100%"} display={"flex"} alignItems={"flex-start"}>
            <KeyboardArrowDown />
          </Box>
        )}
      </Box>
      {showDropDown && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleMyAccount}>My account</MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              handleLogout();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
}