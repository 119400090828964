import CustomToast from "components/shared/CustomToast/CustomToast";
import { SUBSCRIPTION_STATUS } from "components/utils/enums";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/slice/auth.slice";
import styles from "../shared/css/layout.module.scss";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/Sidebar";
import LogoIcon from "static/svgs/logo";
import ProfileMenu from "components/shared/navbar/ProfileMenu/ProfileMenu";
const Layout = ({ showSideBar = true, children }) => {

  const dispatch = useDispatch();
  const subscriptionStatus = useSelector(authActions.selectors.getSubscriptionStatus);
  const currentUser = useSelector(authActions.selectors.getCurrentUser)
  const handleToastClose = () => {
    dispatch(authActions.actions.setSubscriptionStatus(""));
  }
  return (
    <>
      <Toaster position="top-center" />
      {showSideBar ?
        <Sidebar>
          <>
            <CustomToast
              open={subscriptionStatus === SUBSCRIPTION_STATUS.expired}
              onClose={handleToastClose}
              severity="warning"
              message="Your subscription has expired. Please renew it to continue using our services."
              linkText="Renew Now"
              linkUrl="/pricing"
            />
            <CustomToast
              open={subscriptionStatus === SUBSCRIPTION_STATUS.pending}
              onClose={handleToastClose}
              severity="warning"
              message="Your subscription is pending. Please purchase a plan to access our services."
              linkText="Choose a Plan"
              linkUrl="/pricing"
            />

            <Navbar />
            <div className={styles.main}>{children}</div>
          </>
        </Sidebar>
        :
        <div className={styles.noSideBar}>
          <div className={styles.navbar}>
            <LogoIcon />
            <ProfileMenu user={currentUser} showDropDown={true} />
          </div>
          <div className={styles.main}>{children}</div>
        </div>
      }

    </>
  );
};

export default Layout;
