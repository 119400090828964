import { Add, GroupAdd } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'
import CustomTitle from 'components/shared/CustomTitle'
import CustomDialog from 'components/shared/Dialog/CustomDialog'
import MyTable from 'components/shared/Tables/MyTable/MyTable'
import MyTableToolbar from 'components/shared/Tables/MyTable/MyTableToolbar'
import { handleDownloadCsv, handlePrintCsv } from 'components/utils/handlers'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { devicePolicyActions } from '../../../../../redux/slice/devicePolicy.slice'
import { orgGroupsActions } from '../../../../../redux/slice/orgGroups.slice'
import { ASSIGN_DEVICE_POLIICY, DELETE_DEVICE_POLICY, GET_DEVICE_POLICIES } from '../../../../../redux/types/organization.types'
import OrgGroupSelector from '../OrgGroupSelector'
import DevicePolicyForm from './DevicePolicyForm'
import { DEVICE_POLICY_DOWNLOAD_COLS, getDevicePolicyColumns } from './helper'

const DevicePolicies = () => {
    const [isDevicePolicyOpen, setIsDevicePolicyOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState({})
    const [checkedDevicePolicies, setCheckedDevicePolicies] = useState([]);
    const orgGroups = useSelector(orgGroupsActions.selectors.getOrgGroups)

    const handleCheckedDevicePolicies = (ids) => {
        setCheckedDevicePolicies(ids);
    }
    const handleDevicePolicyOpen = () => {
        setIsDevicePolicyOpen(true)
    }
    const handleDevicePolicyClose = () => {
        setSelectedPolicy({})
        setIsDevicePolicyOpen(false)
    };

    const [selectedGroup, setSelectedGroup] = useState({})
    const [openGroup, setOpenGroup] = useState(false);
    const handleGroupOpen = () => {
        if (!checkedDevicePolicies?.length) {
            toast.error("Please Select the Device Policy First");
            return
        }
        setOpenGroup(true)
    }
    const handleGroupClose = () => {
        setOpenGroup(false)
    }


    const handleAssignDevicePolicy = () => {
        const payload = {
            policyIds: checkedDevicePolicies,
            groupId: selectedGroup
        }
        dispatch({ type: ASSIGN_DEVICE_POLIICY, payload });
        handleGroupClose();
    }



    const devicePolicies = useSelector(devicePolicyActions.selectors.getDevicePolicy)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: GET_DEVICE_POLICIES })
    }, [dispatch])

    const handleDeleteDevicePolicy = (id) => {
        dispatch({ type: DELETE_DEVICE_POLICY, payload: id })
    }

    const handleViewPolicy = (row) => {
        setSelectedPolicy(row)
        handleDevicePolicyOpen()
    }

    const devicePolicyColumns = getDevicePolicyColumns({ handleDeleteDevicePolicy, handleViewPolicy })
    const [devicePolicySearchTerm, setDevicePolicySearchTerm] = useState("");
    const handleDevicePolicySearch = (term) => {
        setDevicePolicySearchTerm(term);
    };

    const filteredDevicePolicies = useMemo(() => {
        if (!devicePolicySearchTerm) return devicePolicies;
        const regex = new RegExp(devicePolicySearchTerm, "i");
        return devicePolicies.filter((pkg) =>
            Object.values(pkg).some((value) => regex.test(value))
        );
    }, [devicePolicySearchTerm, devicePolicies]);

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <CustomTitle title={"Device Policies"} count={devicePolicies?.length} infoText={"A device policy is a set of rules controlling users' access and managing device usage."} />
                    <br />
                    <Card>
                        <MyTableToolbar
                            placeHolder={"Search Device Policy"}
                            btn2Txt={"Create Device Policy"}
                            btn2Icon={Add}
                            handleBtn2Click={handleDevicePolicyOpen}
                            btn1Txt={"Assign Device Policy"}
                            btn1Icon={GroupAdd}
                            handleBtn1Click={handleGroupOpen}
                            disableExport={true}
                            onSearch={handleDevicePolicySearch}
                        />
                        <MyTable
                            checkboxSelection={true}
                            columns={devicePolicyColumns}
                            rows={filteredDevicePolicies}
                            id={(row) => row?._id}
                            handleSelectedRows={handleCheckedDevicePolicies}
                        />
                    </Card>
                </Grid>
                <CustomDialog title={'Add Device Policy'} isOpen={isDevicePolicyOpen} handleToggle={handleDevicePolicyClose} maxWidth={"lg"}>
                    <DevicePolicyForm policy={selectedPolicy} handleDevicePolicyClose={handleDevicePolicyClose} />
                </CustomDialog>

                {/* Assign Policies to Group Dialog */}
                <CustomDialog title="Select Group" isOpen={openGroup} handleToggle={handleGroupClose} maxWidth={"md"} >
                    <OrgGroupSelector groups={orgGroups} handleAddToGroup={handleAssignDevicePolicy} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} handleClose={handleGroupClose} />
                </CustomDialog >

            </Grid>
        </div>
    )
}

export default DevicePolicies
