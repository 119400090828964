import { InfoSharp } from '@mui/icons-material';
import {
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import RHFSelect from 'components/shared/Forms/RHFSelect';
import RHFTimeSelector from 'components/shared/Forms/RHFTimeSelector';
import CustomPopover from 'components/shared/Popover/CustomPopover';
import theme from 'components/utils/theme';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'yup';
import { settingsActions } from '../../../../redux/slice/settings.slice';
import { GET_SETTINGS, UPDATE_SETTINGS } from '../../../../redux/types/settings.types';
import styles from '../Account.module.scss';
import { authDefs, formAuthenticationSchema, timeIntervalOptions, YupAuthenticationSchema } from './helper';

const AuthenticationSettings = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverText, setPopoverText] = useState('');

    const authenticationSchema = object(YupAuthenticationSchema);
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm(formAuthenticationSchema(authenticationSchema));
    const dispatch = useDispatch();
    const settings = useSelector(settingsActions.selectors.getSettings);

    useEffect(() => {
        if (settings?.authentication) {
            setValue("selectedAuthentication", settings?.authentication?.selectedAuthentication)
            setValue("selectedTime", dayjs(settings?.authentication?.selectedTime));
            setValue("selectedTimeInterval", settings?.authentication?.selectedTimeInterval);
        }
    }, [setValue, settings?.authentication])

    const handleAuthenticationSubmit = (payload) => {
        console.log("payload", payload)
        dispatch({ type: UPDATE_SETTINGS, payload })
    };

    const handlePopoverOpen = (event, text) => {
        setAnchorEl(event.currentTarget);
        setPopoverText(text);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const checkDisable = (value) => {
        return value === watch("selectedAuthentication") ? false : true;
    }

    useEffect(() => {
        dispatch({ type: GET_SETTINGS })
    }, [dispatch])

    return (
        <form onSubmit={handleSubmit(handleAuthenticationSubmit)}>
            <Grid container className={styles.accountCard}>
                <Grid item mb={2} xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Authentication
                    </Typography>
                    <FormLabel>
                        {authDefs.mainDef}                    </FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="selectedAuthentication"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup {...field}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <FormControlLabel
                                            value="scheduled"
                                            control={<Radio size="small" />}
                                            label="Scheduled Authentication"
                                        />
                                        <IconButton
                                            onMouseEnter={(e) =>
                                                handlePopoverOpen(e, authDefs.scheduled)
                                            }
                                            onMouseLeave={handlePopoverClose}
                                            sx={{ padding: 0 }}
                                        >
                                            <InfoSharp fontSize="small" />
                                        </IconButton>
                                        <Grid mt={1} ml={3} item xs={6}>
                                            <RHFTimeSelector
                                                control={control}
                                                name="selectedTime"
                                                label=""
                                                errors={errors}
                                                required={false}
                                                disabled={checkDisable("scheduled")}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value="interval"
                                            control={<Radio size="small" />}
                                            label="Interval-Based Authentication"
                                        />
                                        <IconButton
                                            onMouseEnter={(e) =>
                                                handlePopoverOpen(e, authDefs.interval)
                                            }
                                            onMouseLeave={handlePopoverClose}
                                            sx={{ padding: 0 }}
                                        >
                                            <InfoSharp fontSize="small" />
                                        </IconButton>
                                        <Grid mt={1} ml={3} item xs={6}>
                                            <RHFSelect
                                                control={control}
                                                name="selectedTimeInterval"
                                                label=""
                                                options={timeIntervalOptions}
                                                disabled={checkDisable("interval")}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value="continuous"
                                            control={<Radio size="small" />}
                                            label="Continuous Authentication"
                                        />
                                        <IconButton
                                            onMouseEnter={(e) =>
                                                handlePopoverOpen(e, authDefs.continuous)
                                            }
                                            onMouseLeave={handlePopoverClose}
                                            sx={{ padding: 0 }}
                                        >
                                            <InfoSharp fontSize="small" />
                                        </IconButton>
                                        <Grid mt={1} ml={3} item xs={6}>
                                            <Typography variant='subtitle1' color={checkDisable("continuous") ? "gray.main" : "primary.main"}>{authDefs.continuous}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        )}
                    />
                    {errors.selectedAuthentication && (
                        <Typography color="error">{errors.selectedAuthentication.message}</Typography>
                    )}
                </Grid>
                <Grid item container justifyContent={'flex-end'} xs={12}>
                    <CustomButton
                        padding={"0px 50px"}
                        color={theme.palette.background.default}
                        bgColor={theme.palette.primary.main}
                        hover={theme.palette.info.main}
                        label={"Save Changes"}
                        type={"submit"}
                    />
                </Grid>
                <CustomPopover open={open} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} popoverText={popoverText} />

            </Grid>
        </form>
    );
};

export default AuthenticationSettings;
