import { yupResolver } from "@hookform/resolvers/yup";
import { Delete, ViewList } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import tables from "components/shared/Tables/table.module.scss";
import { formatTimeBdata } from "components/utils";
import { array, boolean, object, string } from "yup";


export const getDevicePolicyColumns = ({ handleDeleteDevicePolicy, handleViewPolicy }) => {
    return [
        { field: "policyName", headerName: "Policy Name", flex: 1 },
        { field: "os", headerName: "OS", flex: 1 },
        {
            field: "details",
            headerName: "Restricted Paths",
            minWidth: 100,
            flex: 2,
            renderCell: (params) => (
                <div style={{ maxWidth: "100%", overflowX: "auto", display: "flex" }}>
                    <div style={{ minWidth: "fit-content" }}>
                        {params.row.paths.map((item, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                className={tables.actionBtn}
                                size="small"
                                sx={{ marginRight: 1, whiteSpace: "nowrap" }}
                            >
                                {item?.path}
                            </Button>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            field: "restrictedTime",
            headerName: "Restricted Time",
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => `${params?.row?.startDate || ""} ${formatTimeBdata(params?.row?.startTime)} - ${params?.row?.endDate || ""} ${formatTimeBdata(params?.row?.endTime)}`
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleViewPolicy(params.row);
                        }}
                    >
                        <ViewList />
                    </IconButton>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteDevicePolicy(params.row._id);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </>
            ),
        },
    ];
}


export const DEVICE_POLICY_DOWNLOAD_COLS = [
    { field: "policyName", headerName: "Policy Name" },
    { field: "os", headerName: "OS" },
    { field: "restrictedTime", headerName: "Restricted Time", type: "date" },
    { field: "paths", headerName: "Restricted Paths" },
];



export const YupDevicePolicySchema = {
    policyName: string().required('Policy name is required'),
    os: string().required('Operating system is required'),
    paths: array().of(
        object().shape({
            path: string().required('Path is required'),
            N: boolean(),
            RX: boolean(),
            R: boolean(),
            W: boolean(),
            D: boolean(),
        }).test('at-least-one', 'At least one checkbox must be selected', function (value) {
            const { N, RX, R, W, D } = value;
            return N || RX || R || W || D;
        })
    ).required('At least one path is required')
};

export const formDevicePolicySchema = (devicePolicySchema) => {
    return {
        resolver: yupResolver(devicePolicySchema),
        mode: 'onChange',
        defaultValues: {
            policyName: '',
            os: 'windows',
            paths: [
                {
                    "path": "",
                    "N": true,
                    "RX": false,
                    "R": false,
                    "W": false,
                    "D": false
                }
            ],
            username: "",
            password: "",
            role: "User",
            startDay: "Mon",
            endDay: "Sat",
            startTime: null,
            endTime: null
        }
    }
}


export const deviceUserRoles = [
    { label: "Administrator", value: "Administrator" },
    { label: "User", value: "User" }
]