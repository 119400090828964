import { Card, Grid } from "@mui/material";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setUpAlertsSocket, unSubscribeSocketTopics } from "../../../redux/saga/socket";
import { alertsActions } from "../../../redux/slice/alerts.slice";
import { FETCH_ALERTS } from "../../../redux/types/devices.types";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import table from "../../shared/Tables/table.module.scss";
import { ALERT_DOWNLOAD_COLS, alertColumns } from "./helper";

const Alerts = () => {
  const [alertSearchTerm, setalertSearchTerm] = useState("");
  const alerts = useSelector(alertsActions.selectors.getAlerts)

  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const payload = {
        deviceId: id
      }
      dispatch({ type: FETCH_ALERTS, payload })
    }
  }, [dispatch, id]);

  useEffect(() => {
    setUpAlertsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics(["FIM_ALERTS"]);
    };
  }, [dispatch]);

  const handleAlertSearch = (term) => {
    setalertSearchTerm(term);
  };

  const filteredAlerts = useMemo(() => {
    if (!alertSearchTerm) {
      return [...alerts].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    const regex = new RegExp(alertSearchTerm, "i");
    return alerts.filter((alert) =>
      Object.values(alert).some((value) => regex.test(value))
    );
  }, [alerts, alertSearchTerm]);



  return (
    <div>
      <Grid
        my={"15px"}
        container
        sx={{ justifyContent: { xl: "space-between", md: "center" } }}
        spacing={2}
      ></Grid>
      {/* <Grid my={"15px"} container spacing={2}>
        <Grid item md={12} lg={8}>
          <DeviceGraph />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <TotalAlerts total={alerts?.length} />
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} className={table.tableContainer}>
          <CustomTitle title={"Alerts"} count={filteredAlerts?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Alerts"}
              onSearch={handleAlertSearch}
              onDownload={() =>
                handleDownloadCsv({ rows: filteredAlerts, columns: ALERT_DOWNLOAD_COLS, name: `All_Alerts` })
              }
              onPrint={() => handlePrintCsv({ rows: filteredAlerts, columns: ALERT_DOWNLOAD_COLS })}
              isAlerts={true}
            />
            <MyTable
              checkboxSelection={false}
              columns={alertColumns}
              rows={filteredAlerts}
            />
          </Card>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default Alerts;
