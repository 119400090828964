import Subscription from 'components/modules/Subscription/Subscription'
import React from 'react'

const SubscriptionPage = () => {
    return (
        <>
            <Subscription cols={10} changePlanLink={"/pricing"} />
        </>
    )
}

export default SubscriptionPage
