import { Card, Popover } from '@mui/material';
import React from 'react';
import ColoredIcon from '../../shared/ColoredIcon';
import text from '../../shared/css/text.module.scss';
import styles from './singleDashboard.module.scss';
const InfoCard = ({ icon, bgColor, title, body, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card className={styles.dashCard} onClick={onClick}>
      <ColoredIcon icon={icon} bgColor={bgColor} />
      <p style={{ color: bgColor }} className={text.heading2}>{title}</p>
      <p
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={`${styles.bodyText} ${text.heading2}`}>{body}</p>
      {body?.length > 14 && (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <p className={text.heading2}>{body}</p>
        </Popover>
      )}
    </Card>
  )
}

export default InfoCard
