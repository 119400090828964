import { Box, Card, Divider, Typography } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import text from '../../shared/css/text.module.scss';
import styles from './pricingPlan.module.scss';

const CurrentPlanCard = ({ title, handleClickButton, selectedPlan, isYearly, buttonDetails, discountPercent, isFreeTrial = false }) => {
    const originalPrice = isYearly ? selectedPlan?.yearlyPrice * 12 : selectedPlan?.monthlyPrice;
    const discountedPrice = discountPercent
        ? (originalPrice - (originalPrice * discountPercent) / 100).toFixed(2)
        : originalPrice;

    return (
        <Card className={styles.subscriptionPlanCard}>
            <Typography variant="body1" fontWeight="bold">{title}</Typography>
            <div className={styles.innerCard}>
                <div className={styles.flexContainer}>
                    <div className={styles.planDetail}>
                        <Typography variant="body1" fontWeight="bold">
                            {selectedPlan?.name} Subscription Package
                        </Typography>

                        {/* Price Display with Discount */}
                        {discountPercent > 0 ? (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography
                                    fontWeight="bold"
                                    className={styles.lineThrough}
                                >
                                    <b>${originalPrice}</b>
                                </Typography>
                                <Typography fontWeight="bold" className={text.info1} color="primary.main">
                                    <b>${discountedPrice}</b>/ Per {isYearly ? "Year" : "Month"}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography fontWeight="bold" className={text.info1}>
                                <b>${originalPrice}/</b>Per {isYearly ? "Year" : "Month"}
                            </Typography>
                        )}

                    </div>

                    {/* Action Button */}
                    <CustomButton
                        padding={"0px 30px"}
                        color={buttonDetails.color}
                        bgColor={buttonDetails.bgColor}
                        hover={buttonDetails.hover}
                        label={buttonDetails.label}
                        onClick={handleClickButton}
                        type={"text"}
                    />
                </div>
                {isFreeTrial &&
                    <>
                        <Divider />
                        <span className={styles.trialBox}>
                            Free Trial
                        </span>
                        <Typography variant="body2" fontWeight="bold"> Enjoy a  <span className={styles.pricingInfo}> 1-month free trial!</span> After that, it's just <span className={styles.pricingInfo}> <b>${originalPrice}/</b>Per {isYearly ? "Year" : "Month"}</span> </Typography>
                    </>}
            </div>


        </Card>
    );
}

export default CurrentPlanCard;
