import { getBadge } from "components/shared/Tables/helper";
import { formatDateBdata } from "components/utils";

export const devicesColumns = [
    { field: "deviceName", headerName: "Device Name", flex: 1 },
    {
        field: "os",
        headerName: "OS",
        flex: 1,
        renderCell: (params) => {
            return params?.row?.systemDetails?.os;
        },
    },
    {
        field: "ip",
        headerName: "IP",
        flex: 1,
        renderCell: (params) => {
            return params?.row?.systemDetails?.ip;
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => getBadge(params?.row?.status),
    },
    {
        field: "lastActive",
        headerName: "Last Active",
        flex: 1.3,
        renderCell: (params) => {
            return formatDateBdata(params?.row?.lastActive)
        },

    },
];

export const ORG_DEVICE_DOWNLOAD_COLS = [
    { field: "deviceName", headerName: "Device Name" },
    { field: "systemDetails.os", headerName: "OS", nested: true },
    { field: "systemDetails.ip", headerName: "IP", nested: true },
    { field: "status", headerName: "Status" },
    { field: "lastActive", headerName: "Last Active" },
];
