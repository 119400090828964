import { Box, Grid } from '@mui/material';
import AppCard from 'components/modules/Apps/AppCard';
import CustomNavTabs from 'components/shared/CustomNavTabs';
import { useState } from 'react';
import styles from './deviceAccess.module.scss';
import DeviceAdministration from './DeviceAdministration';
import FileFolderAccess from './FileFolderAccess';
import { deviceUserItems, getDeviceUserItems } from './helper';
import TimeBasedAccess from './TimeBasedAccess';

const DeviceUserPopup = ({ user }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    };


    const handleComponents = (tab) => {
        // eslint-disable-next-line default-case
        switch (tab) {
            case 0:
                return <FileFolderAccess />;
            case 1:
                return <TimeBasedAccess />
            case 2:
                return <DeviceAdministration />

            default:
                <FileFolderAccess />;
        }
    };

    return (
        <Box className={styles.appCardContainer} >
            <Grid container >

                <div className={styles.innerContainer}>
                    {getDeviceUserItems()?.map((item, idx) => (
                        <AppCard {...item} key={idx} />
                    ))}
                </div>
            </Grid>
            <br />
            <CustomNavTabs
                selectedTab={selectedTab}
                handleSelectedTab={handleSelectedTab}
                navItems={deviceUserItems}
            />

            {handleComponents(selectedTab)}
        </Box>
    )
}

export default DeviceUserPopup
