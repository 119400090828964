export const TOPICS = {
    PORTS: "PORTS",
    SYSTEM: "SYSTEM",
    NETWORK_INTERFACES: "NETWORK_INTERFACES",
    PROCESSES: "PROCESSES",
    SYSTEM_PACKAGES: "SYSTEM_PACKAGES",
    APPLICATIONS: "APPLICATIONS",
    UNINSTALL_APP: "UNINSTALL_APP",
    EXECUTE_COMMAND: "EXECUTE_COMMAND",
    DEVICES: "DEVICES",
    ALL_APPS: "ALL_APPS",
    CONNECTION: "CONNECTION",
    EXECUTE_RESPONSE: "EXECUTE_RESPONSE",
    USERS: "USERS"
}


export const DeviceStatus = {
    registered: "Registered",
    connected: "Connected",
    disconnected: "Disconnected",
    activated: "Activated",
    add_credentials: "Add Credentials",
    verified: "Verified",
    verifying: "Verifying",
    partialConnected: "Partially Connected",
    reScanning: "Re-Scanning"
}

export const OS = {
    windows: "Windows",
    ubuntu: "Ubuntu"
}

export const SUBSCRIPTION_STATUS = {
    active: "active",
    canceled: "canceled",
    incomplete_expired: "incomplete_expired",
    incomplete: "incomplete",
    trialing: "trialing",
    past_due: "past_due",
    unpaid: "unpaid",
    paused: "paused",
    expired: "expired",
    failed: "failed",
    pending: "pending"
};


export const DummyNetwork = [
    {
        "parentDevice": {

            "orgId": "xzzzo",
            "keysCount": 13,
            "deviceId": "devIRGV6XKO1zoCuZH2FH",
            "deviceName": "cybersecurity-PowerEdge-R730xd",
            "networkId": "netiZYQD2I4uhnsnXiI2o",
            "status": "Disconnected",
            "deviceType": "network",
            "createdAt": "2025-03-05T20:05:50.583Z",
            "__v": 0,
            "activeSince": "2025-03-06T11:24:48.972Z",
            "assetId": "4257d8f759e4b67ab4f733ac96a2f6e7d3fc725e894bbcc6a493e1a163c19a24",
            "systemDetails": {
                "os": "Ubuntu",
                "ip": "127.0.1.1"
            },
            "uid": "217321e45ba5962269fdceeac41ebf930d47489cc8f3d37b384a8f56f034ef52",
            "internalIp": "192.168.2.7",
            "lastAuthenticated": "2025-03-11T21:08:42.448Z",
            "lastActive": "2025-03-11T21:08:42.440Z",
            "id": "67c8ae9ea99c1e08aa752a67"
        },
        "orgId": "xzzzo",
        "childDevices": [
            {
                "_id": "67c985b7d7307f164d590b8e",
                "orgId": "xzzzo",
                "deviceId": "devC3BStYKT4pz9FmMAeL",
                "deviceName": "bdata5-4",
                "networkId": "netiZYQD2I4uhnsnXiI2o",
                "status": "Disconnected",
                "deviceType": "network",
                "createdAt": "2025-03-06T11:23:35.228Z",
                "__v": 0,
                "activeSince": "2025-03-06T11:25:39.026Z",
                "assetId": "88708574a6c8195637ad50fc7fd25dbe785c5fb46a73b1f946da7beaa54939f5",
                "systemDetails": {
                    "os": "Ubuntu",
                    "ip": "192.168.2.54"
                },
                "uid": "f10c21b1e25096379269563e20c3b7da313d32ed509ca8341f5567cb10da9678",
                "internalIp": "192.168.2.54",
                "lastAuthenticated": "2025-03-06T12:22:13.844Z",
                "lastActive": "2025-03-06T12:22:13.847Z",
                "id": "67c985b7d7307f164d590b8e"
            },
            {
                "_id": "67c985b7d7307f164d590b8f",
                "orgId": "xzzzo",
                "deviceId": "devG1FDVyg0iZZzvSK2Bb",
                "deviceName": "BData7-1",
                "networkId": "netiZYQD2I4uhnsnXiI2o",
                "status": "Disconnected",
                "deviceType": "network",
                "createdAt": "2025-03-06T11:23:35.228Z",
                "__v": 0,
                "activeSince": "2025-03-06T11:26:25.105Z",
                "assetId": "b06d4905b41dc4b8f762e1edd7db83410bc2558bba5e2fea27bf0438dc9ff734",
                "systemDetails": {
                    "os": "Ubuntu",
                    "ip": "127.0.1.1"
                },
                "uid": "815eae82cbbf0f384081870dc1ba5a18b1219fdcbfb459fef5d63decb5d8cf5a",
                "internalIp": "192.168.2.71",
                "lastAuthenticated": "2025-03-06T12:22:11.634Z",
                "lastActive": "2025-03-06T12:22:10.970Z",
                "id": "67c985b7d7307f164d590b8f"
            },
            {
                "_id": "67c985b7d7307f164d590b90",
                "orgId": "xzzzo",
                "deviceId": "devkS023tIEC1nCTP9pyN",
                "deviceName": "BData8-1",
                "networkId": "netiZYQD2I4uhnsnXiI2o",
                "status": "Disconnected",
                "deviceType": "network",
                "createdAt": "2025-03-06T11:23:35.228Z",
                "__v": 0,
                "activeSince": "2025-03-06T11:28:28.606Z",
                "assetId": "57262ba74cd1687d4339fb7efb9736aa1c32a413779908e4d109bbeea76c41bc",
                "systemDetails": {
                    "os": "Ubuntu",
                    "ip": "127.0.1.1"
                },
                "uid": "df44ae418e1624661cf2847e8d27a166686cdf6a89adb3309d9fc4d93365ed0b",
                "internalIp": "192.168.2.81",
                "lastAuthenticated": "2025-03-06T12:22:13.859Z",
                "lastActive": "2025-03-06T12:22:13.864Z",
                "id": "67c985b7d7307f164d590b90"
            },
            {
                "_id": "67c985b7d7307f164d590b91",
                "orgId": "xzzzo",
                "deviceId": "devqvuG5yjjTS0wVxZUcC",
                "deviceName": "BData4-1",
                "networkId": "netiZYQD2I4uhnsnXiI2o",
                "status": "Disconnected",
                "deviceType": "network",
                "createdAt": "2025-03-06T11:23:35.229Z",
                "__v": 0,
                "activeSince": "2025-03-06T11:32:12.266Z",
                "assetId": "45bdd434b4d3e0ffcef1bdd8cb12f1d4f4c1c4c495f9a132def42a4134bce30b",
                "systemDetails": {
                    "os": "Ubuntu",
                    "ip": "127.0.1.1"
                },
                "uid": "5d12183126dc4b262eac696c01a4218c1e62309354abe44a40555950a4f0a7ba",
                "internalIp": "192.168.2.41",
                "lastAuthenticated": "2025-03-06T12:22:11.116Z",
                "lastActive": "2025-03-06T12:22:10.759Z",
                "id": "67c985b7d7307f164d590b91"
            },
        ],
        "status": "Disconnected",

        "createdAt": "2025-03-05T20:05:50.588Z",
        "__v": 0,
        "id": "67c8ae9ea99c1e08aa752a69"
    },
    {
        "_id": "67c982f6a99c1e08aa7c0023",
        "networkId": "nettgNHPwe7f8i92DHCVc",
        "deviceId": "devxf7BcInjMhZk6Ys7Kt",
        "parentDevice": {
            "_id": "67c982f6a99c1e08aa7c0021",
            "orgId": "xzzzo",
            "keysCount": 4,
            "deviceId": "devxf7BcInjMhZk6Ys7Kt",
            "deviceName": "Bdata-H8HZ-XZZZO",
            "networkId": "nettgNHPwe7f8i92DHCVc",
            "status": "Registered",
            "deviceType": "network",
            "createdAt": "2025-03-06T11:11:50.710Z",
            "__v": 0,
            "id": "67c982f6a99c1e08aa7c0021"
        },
        "orgId": "xzzzo",
        "childDevices": [],
        "status": "Registered",
        "localIps": [],
        "credentials": [],
        "connections": [],
        "createdAt": "2025-03-06T11:11:50.715Z",
        "__v": 0,
        "id": "67c982f6a99c1e08aa7c0023"
    },
    {
        "_id": "67de9f51a5e3f5fc562d53ab",
        "networkId": "net82e9B5WDxWmvPLpF2X",
        "deviceId": "dev01dz1IxkPkWf0abapD",
        "parentDevice": {
            "_id": "67de9f51a5e3f5fc562d53a9",
            "orgId": "xzzzo",
            "keysCount": 13,
            "deviceId": "dev01dz1IxkPkWf0abapD",
            "deviceName": "Bdata-DT3E-XZZZO",
            "networkId": "net82e9B5WDxWmvPLpF2X",
            "status": "Registered",
            "deviceType": "network",
            "createdAt": "2025-03-22T11:30:25.716Z",
            "__v": 0,
            "id": "67de9f51a5e3f5fc562d53a9"
        },
        "orgId": "xzzzo",
        "childDevices": [],
        "status": "Registered",
        "localIps": [],
        "credentials": [],
        "connections": [],
        "createdAt": "2025-03-22T11:30:25.719Z",
        "__v": 0,
        "id": "67de9f51a5e3f5fc562d53ab"
    }
]