import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    subscriptionPlans: [],
    selectedPricingTab: 0,
    selectedPlan: {},
    isYearlyBilling: true,
    stripePaymentSecret: {},
    currentPaymentMethod: {},
    subscriptionDiscount: {},
    isTrailAvailable: false
}

const name = 'subscription'
const parentSelector = state => state?.[name]

const subscriptionSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetSubscription: () => {
            return initialState;
        },
        setSubscriptionPlans: (state, action) => {
            return {
                ...state,
                subscriptionPlans: action.payload,
            };
        },
        setSelectedPricingTab: (state, action) => {
            return {
                ...state,
                selectedPricingTab: action.payload,
            };
        },
        setSelectedPlan: (state, action) => {
            return {
                ...state,
                selectedPlan: action.payload,
            };
        },
        setIsYearlyBilling: (state, action) => {
            return {
                ...state,
                isYearlyBilling: action.payload,
            };
        },
        setStripePaymentSecret: (state, action) => {
            return {
                ...state,
                stripePaymentSecret: action.payload,
            };
        },
        setCurrentPaymentMethod: (state, action) => {
            return {
                ...state,
                currentPaymentMethod: action.payload,
            };
        },
        setSubscriptionDiscount: (state, action) => {
            return {
                ...state,
                subscriptionDiscount: action.payload,
            };
        },
        setIsTrailAvailable: (state, action) => {
            return {
                ...state,
                isTrailAvailable: action.payload,
            };
        },
    },
});


const getSubscriptionPlans = createSelector(parentSelector, state => {
    return state?.subscriptionPlans
})

const getSelectedPricingTab = createSelector(parentSelector, state => {
    return state?.selectedPricingTab
})

const getSelectedPlan = createSelector(parentSelector, state => {
    return state?.selectedPlan
})

const getIsYearlyBilling = createSelector(parentSelector, state => {
    return state?.isYearlyBilling
})

const getStripePaymentSecret = createSelector(parentSelector, state => {
    return state?.stripePaymentSecret
})

const getCurrentPaymentMethod = createSelector(parentSelector, state => {
    return state?.currentPaymentMethod
})

const getSubscriptionDiscount = createSelector(parentSelector, state => {
    return state?.subscriptionDiscount
})

const getIsTrailAvailable = createSelector(parentSelector, state => {
    return state?.isTrailAvailable
})
const actions = {
    ...subscriptionSlice.actions
}

const selectors = {
    getSubscriptionPlans,
    getSelectedPricingTab,
    getSelectedPlan,
    getIsYearlyBilling,
    getStripePaymentSecret,
    getCurrentPaymentMethod,
    getSubscriptionDiscount,
    getIsTrailAvailable
}

export const subscriptionActions = { actions, selectors }


export default subscriptionSlice;