export const BlockChainBlockUrl = () => {
    return `data:image/svg+xml;utf8,${encodeURIComponent(
        `<svg width="18" height="18" viewBox="0 0 182 182" fill="#0C5E9B" xmlns="http://www.w3.org/2000/svg">
            <path d="M147.656 56.5792L92.2439 24.587C91.8657 24.3683 91.4366 24.2532 90.9998 24.2532C90.5629 24.2532 90.1338 24.3683 89.7556 24.587L34.3438 56.5792C33.9653 56.7972 33.651 57.1111 33.4326 57.4893C33.2142 57.8675 33.0993 58.2966 33.0996 58.7333V122.718C33.0995 123.155 33.2144 123.584 33.4328 123.962C33.6512 124.341 33.9654 124.655 34.3438 124.873L89.7556 156.865C90.134 157.083 90.5631 157.198 90.9998 157.198C91.4365 157.198 91.8655 157.083 92.2439 156.865L147.656 124.873C148.034 124.655 148.348 124.341 148.567 123.962C148.785 123.584 148.9 123.155 148.9 122.718V58.7333C148.9 58.2966 148.785 57.8675 148.567 57.4893C148.349 57.1111 148.034 56.7972 147.656 56.5792ZM90.9998 29.6147L141.435 58.7333L90.9998 87.8519L40.5645 58.7333L90.9998 29.6147ZM38.0762 63.043L88.5115 92.1616V150.4L38.0762 121.286V63.043ZM143.923 121.286L93.4881 150.4V92.1616L143.923 63.043V121.286Z" fill="#0C5E9B" />
        </svg>`
    )}`
}

export const SelectedBlockChainBlockUrl = () => {
    return `data:image/svg+xml;utf8,${encodeURIComponent(
        `<svg width="18" height="18" viewBox="0 0 182 182" fill="#00A307" xmlns="http://www.w3.org/2000/svg">
            <path d="M147.656 56.5792L92.2439 24.587C91.8657 24.3683 91.4366 24.2532 90.9998 24.2532C90.5629 24.2532 90.1338 24.3683 89.7556 24.587L34.3438 56.5792C33.9653 56.7972 33.651 57.1111 33.4326 57.4893C33.2142 57.8675 33.0993 58.2966 33.0996 58.7333V122.718C33.0995 123.155 33.2144 123.584 33.4328 123.962C33.6512 124.341 33.9654 124.655 34.3438 124.873L89.7556 156.865C90.134 157.083 90.5631 157.198 90.9998 157.198C91.4365 157.198 91.8655 157.083 92.2439 156.865L147.656 124.873C148.034 124.655 148.348 124.341 148.567 123.962C148.785 123.584 148.9 123.155 148.9 122.718V58.7333C148.9 58.2966 148.785 57.8675 148.567 57.4893C148.349 57.1111 148.034 56.7972 147.656 56.5792ZM90.9998 29.6147L141.435 58.7333L90.9998 87.8519L40.5645 58.7333L90.9998 29.6147ZM38.0762 63.043L88.5115 92.1616V150.4L38.0762 121.286V63.043ZM143.923 121.286L93.4881 150.4V92.1616L143.923 63.043V121.286Z" fill="#00A307" />
        </svg>`
    )}`
}



