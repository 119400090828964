export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_VERIFY = "USER_VERIFY";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPDATE_USER = "UPDATE_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const DELETE_USER = "DELETE_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const TWO_FA = "TWO_FA";
export const SEND_TWO_FA_OTP = "SEND_TWO_FA_OTP";
export const VERIFY_TWO_FA_OTP = "VERIFY_TWO_FA_OTP";
export const SIGN_UP = "SIGN_UP";
export const VALIDATE_ACCESS_TOKEN = "VALIDATE_ACCESS_TOKEN";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
