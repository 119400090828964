// Profile.js
import React, { useRef } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import styles from "./Profile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/slice/auth.slice";
import { UPLOAD_PROFILE_IMAGE } from "../../../../redux/types/auth.types";

const Profile = ({ name, accountType, imageUrl, orgId }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const uploadStatus = useSelector(
    authActions.selectors.getProfileImageUploadStatus
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: file });
    }
  };

  return (
    <Box className={styles.cardContainer}>
      <Box className={styles.cardBanner}></Box>
      <Box className={styles.cardContent}>
        <Box className={styles.profilePicContainer}>
          <Avatar
            src={imageUrl}
            alt={`${name}'s profile`}
            className={styles.profilePic}
          />
          {uploadStatus === "loading" && (
            <CircularProgress className={styles.loadingIndicator} />
          )}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <IconButton className={styles.editButton} onClick={handleButtonClick}>
            <PhotoCamera />
          </IconButton>
        </Box>
        <Typography variant="h6" className={styles.name}>{name}</Typography>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4} mt={1}>

          <Typography variant="body2" className={styles.accountType}>
            Account Type: <span className={styles.type}>{accountType}</span>
          </Typography>
          <Typography variant="body2" className={styles.accountType}>
            Organization Id: <span className={styles.type}>{orgId}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
