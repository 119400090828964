import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useState } from 'react'
import { IOSSwitch } from '../helper'
import { weekDays } from './helper'

const TimeBasedAccess = () => {
    const [isTimeRestricted, setIsTimeRestricted] = useState(true);
    const handleTimeSwitch = () => {
        setIsTimeRestricted(prevState => !prevState);
    }
    return (
        <Grid container>
            <Grid item xs={12} container alignItems={"center"} spacing={3}>
                <Grid item xs={6} display={'flex'} flexDirection={'column'}>
                    {isTimeRestricted ? (
                        <>
                            <Typography variant='body1'>Restrict to login at specific time</Typography>
                            <Typography variant='subtitle2'>
                                Limit device users to logging in only at certain times
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant='body1'>Remove Time Restriction</Typography>
                            <Typography variant='subtitle2'>
                                You can disable the login time restrictions for device users.
                            </Typography>
                        </>
                    )}
                </Grid>

                <Grid item xs={6} display={'flex'} justifyContent={'flex-end'} >
                    <IOSSwitch onClick={() => handleTimeSwitch()}
                    />
                </Grid>
            </Grid>
            {!isTimeRestricted &&
                <Grid item xs={12} container spacing={3}>
                    <Grid mt={2} item xs={6} display={'flex'} flexDirection={'column'} gap={1} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Start Day</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Start Day"
                                defaultValue={"Mon"}
                            >
                                {weekDays?.map((item) => (
                                    <MenuItem key={item?.value} value={item?.value} >
                                        {item?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid mt={2} item xs={6} display={'flex'} flexDirection={'column'} gap={1} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">End Day</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="End Day"
                                defaultValue={"Sat"}
                            >
                                {weekDays?.map((item) => (
                                    <MenuItem key={item?.value} value={item?.value} >
                                        {item?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid mt={2} item xs={6} display={'flex'} flexDirection={'column'} gap={1} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="Start Time"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid mt={2} item xs={6} display={'flex'} flexDirection={'column'} gap={1} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="End Time"
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            }


            {/* <Grid item mt={2} spacing={2} xs={12} container >
                    <Grid item xs={8}>

                    </Grid>
                    <Grid item xs={2}>
                        <CustomButton fullWidth={true} color={'#000'} bgColor={theme.palette.gray.bg} hover={theme.palette.gray.light} label={"Cancel"} />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomButton fullWidth={true} bgColor={theme.palette.primary.main} hover={theme.palette.primary.light} label={"Save"} />
                    </Grid>
                </Grid> */}

        </Grid>
    )
}

export default TimeBasedAccess
