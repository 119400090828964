import dayjs from 'dayjs';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { devicesActions } from '../../../redux/slice/devices.slice';
import StatusIcon from '../../../static/svgs/Status.js';
import ActiveIcon from '../../../static/svgs/active.js';
import CriticalIcon from '../../../static/svgs/critical';
import IdIcon from '../../../static/svgs/id';
import RegisterIcon from '../../../static/svgs/register.js';
import RemoteIcon from '../../../static/svgs/remote.js.js';
import SettingsIcon2 from '../../../static/svgs/settings2.js';
import theme from '../../utils/theme';
import InfoCard from './InfoCard';
import styles from './singleDashboard.module.scss';
import { openRemoteConsole } from './helper.js';
import { DeviceStatus } from 'components/utils/enums';
import toast from 'react-hot-toast';
const DashboardCards = () => {

    const currentDevice = useSelector(devicesActions.selectors.getCurrentDevice);
    const handleRemoteConsole = () => {
        if (currentDevice?.status !== DeviceStatus?.connected) {
            toast.error("Cannot open remote console: Device is disconnected");
            return
        }
        openRemoteConsole(currentDevice?._id)
    }

    const dashboardCards = useMemo(() => {
        return [
            { title: 'Device Id', body: currentDevice?.deviceId, icon: <IdIcon />, bgColor: theme.palette.primary.light, onClick: () => { } },
            { title: 'Device Name', body: currentDevice?.deviceName, icon: <CriticalIcon />, bgColor: theme.palette.primary.main, onClick: () => { } },
            { title: 'Status', body: currentDevice?.status, icon: <StatusIcon />, bgColor: theme.palette.secondary.main, onClick: () => { } },
            { title: 'Registration Date', body: dayjs(currentDevice?.createdAt).format('DD-MM-YYYY hh:mm a'), icon: <RegisterIcon />, bgColor: theme.palette.primary.light, onClick: () => { } },
            { title: 'Last Active', body: dayjs(currentDevice?.lastActive).format('DD-MM-YYYY hh:mm a'), icon: <ActiveIcon width='17' height='17' />, bgColor: theme.palette.secondary.main, onClick: () => { } },
            { title: 'Remote Access', body: 'Console', icon: <RemoteIcon />, bgColor: theme.palette.primary.main, onClick: () => handleRemoteConsole() },
        ]
    }, [currentDevice]);

    return (
        <div className={styles.cardContainer} >
            {dashboardCards?.map((item, idx) => (
                <InfoCard key={idx} icon={item?.icon} bgColor={item?.bgColor} title={item?.title} body={item?.body} onClick={item?.onClick} />
            ))}
        </div>
    )
}

export default memo(DashboardCards);
