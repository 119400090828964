import { Box } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import theme from 'components/utils/theme';
import * as d3 from 'd3';
import { useEffect, useMemo, useRef, useState } from 'react';
import GraphTitle from './GraphTitle';
import { generateStatusChartData } from './helper';
import { ContentPasteOff } from '@mui/icons-material';
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';

const DeviceStatusChart = ({ devices }) => {
    const ref = useRef();

    // Sample chart data
    // const chartData = {
    //     Registered: 15,
    //     Activated: 10,
    //     Connected: 10,
    //     Disconnected: 5,
    // };

    const [loading, setLoading] = useState(true);
    const [showNoData, setShowNoData] = useState(false);

    useEffect(() => {
        if (devices?.length) {
            setLoading(false);
            setShowNoData(false);
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
                setShowNoData(true);
            }, 2000); // Show "No Data" after 2 seconds if data is still not available
            return () => clearTimeout(timer);
        }
    }, [devices?.length]);

    const chartData = useMemo(() => {
        return generateStatusChartData(devices);
    }, [devices]);

    // Mapping specific statuses to specific theme colors
    const statusColors = {
        Registered: theme.palette.warning.main,
        Activated: theme.palette.brown.main,
        Connected: theme.palette.success.main,
        Disconnected: theme.palette.error.main
    };

    const width = 600;
    const height = 300;
    const radius = Math.min(width, height) / 3;
    const showLabels = true;

    useEffect(() => {
        const svgElement = d3.select(ref.current);

        // Clear any previous SVG elements before appending new ones
        svgElement.selectAll("*").remove();

        // Keep all statuses including those with 0 values
        const filteredChartData = Object.entries(chartData);

        const color = d3.scaleOrdinal()
            .domain(filteredChartData.map(d => d[0]))
            .range(filteredChartData.map(d => statusColors[d[0]]));

        // Append the SVG object to the div
        const svg = svgElement
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);

        const pie = d3.pie()
            .sort(null) // Do not sort group by size
            .value(d => d[1] === 0 ? 0.01 : d[1]);  // Ensure 0 values get a minimal slice

        const data_ready = pie(filteredChartData);

        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius * 0.8);

        const outerArc = d3.arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9);

        svg
            .selectAll('allSlices')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', d => color(d.data[0]))
            .attr("stroke", "white")
            .style("stroke-width", "2px")
            .style("opacity", 0.7);

        if (showLabels) {
            // Add lines from pie slices to labels
            svg
                .selectAll('allPolylines')
                .data(data_ready.filter(d => d.data[1] > 0)) // Filter out zero values for labels
                .enter()
                .append('polyline')
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('fill', 'none')
                .attr('points', function (d) {
                    const pos = outerArc.centroid(d);
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                    pos[0] = radius * 1.1 * (midangle < Math.PI ? 1 : -1);
                    return [arc.centroid(d), outerArc.centroid(d), pos];
                });

            const labels = svg
                .selectAll('allLabels')
                .data(data_ready.filter(d => d.data[1] > 0)) // Filter out zero values for labels
                .enter()
                .append('g')
                .attr('class', 'label-group')
                .attr('transform', function (d) {
                    const pos = outerArc.centroid(d);
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                    pos[0] = radius * 1.5 * (midangle < Math.PI ? 1 : -1); // Positioning the label outside
                    return `translate(${pos})`;
                });

            // Add background rectangles behind the text
            labels.append('rect')
                .attr('x', -30)
                .attr('y', -20)
                .attr('width', 100)
                .attr('height', 40)
                .attr('fill', theme.palette.primary.main)
                .attr('rx', 5)
                .attr('ry', 5);

            // Add the text labels with two lines using tspan
            labels.append('text')
                .style('fill', 'white')
                .style('font-size', '12px')
                .style('text-anchor', 'middle')
                .style('alignment-baseline', 'middle')
                .each(function (d) {
                    const textElement = d3.select(this);

                    textElement.append('tspan')
                        .attr('x', 20)
                        .attr('dy', '-0.5em')
                        .text(d.data[0]);

                    textElement.append('tspan')
                        .attr('x', 20)
                        .attr('dy', '1.2em')
                        .text(d.data[1]);
                });
        }

    }, [chartData, height, radius, width, showLabels]);

    return (
        <MyCard height={'350px'}>
            <GraphTitle showTimeMenu={false} title={'Device Status Overview'} />
            {loading ? (
                <GraphSkelton height={280} />
            ) : showNoData ? (
                <div className={tableStyles.noData}>
                    <ContentPasteOff /> No Data Available
                </div>
            ) : (
                <Box display={'flex'} justifyContent={'center'} style={{ height: height }}>
                    <svg ref={ref}></svg>
                </Box>
            )}
        </MyCard>
    );
}

export default DeviceStatusChart;
