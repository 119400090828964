import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Stack, Typography } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import theme from 'components/utils/theme';
import styles from './pricingPlan.module.scss';

const PricingPlanCard = ({ plan, isYearly, handleSubmitPlan, discountPercent, isTrialAvailable, isCustomPrice }) => {
    const originalPrice = isYearly ? plan?.yearlyPrice : plan?.monthlyPrice;
    const discountedPrice = discountPercent
        ? (originalPrice - (originalPrice * discountPercent) / 100).toFixed(2)
        : originalPrice;

    return (
        <Card className={styles.priceCard} >
            <Typography className={styles.priceCardTitle}>{plan?.name}</Typography>

            <Typography className={styles.priceCardPrice}>
                <span className={styles.priceCardCurrency}>
                    {!isCustomPrice && "$"}
                </span>
                {isCustomPrice ? (
                    <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
                        Custom
                    </span>
                ) : discountPercent ? (
                    <>
                        <span className={styles.lineThrough}>{originalPrice}</span>
                        <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
                            {discountedPrice}
                        </span>
                    </>
                ) : (
                    originalPrice
                )}
                {!isCustomPrice && <span className={styles.priceCardDuration}>/month</span>}
            </Typography>


            {/* <Typography className={styles.priceCardDescription}>{plan?.description}</Typography> */}
            <Typography className={styles.priceCardFeaturesHeading}>What’s included</Typography>

            <Stack spacing={1} className={styles.priceCardFeatureList}>
                <Stack direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                    <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                    <Typography>No. of Users: {plan?.userLimit ? plan?.userLimit : "Unlimited"}</Typography>
                </Stack>
                <Stack direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                    <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                    <Typography>No. of Devices: {plan?.deviceLimit ? plan?.deviceLimit : "Unlimited"}</Typography>
                </Stack>
                {plan?.features.map((feature, index) => (
                    <Stack key={index} direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                        <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                        <Typography>{feature}</Typography>
                    </Stack>
                ))}
            </Stack>

            <CustomButton
                padding={"0px 0px"}
                fullWidth={true}
                color={theme.palette.background.default}
                bgColor={theme.palette.primary.main}
                hover={theme.palette.info.main}
                label={isCustomPrice ? "Contact Sales" : isTrialAvailable ? "Get 1 Month Free Trial" : "Get Started"}
                type={"submit"}
                height="35px"
                onClick={() => handleSubmitPlan(plan, isYearly)}
            />
        </Card>
    );
};

export default PricingPlanCard;
