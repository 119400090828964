import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import MyCard from 'components/shared/Card/Card';
import theme from 'components/utils/theme';
import PieChart from '../Device/PieChart';
import CustomLegend from './CustomLegend';
import GraphTitle from './GraphTitle';
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';

const UserRoleDistribution = ({ userRoles = { users: 0, admins: 0, pendingInvites: 0 } }) => {
    const [loading, setLoading] = useState(true);
    const [showNoData, setShowNoData] = useState(false);

    useEffect(() => {
        const total = userRoles?.admins + userRoles?.users + userRoles?.pendingInvites;
        if (total > 0) {
            setLoading(false);
            setShowNoData(false);
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
                setShowNoData(true);
            }, 2000); // Show "No Data" after 2 seconds if data is still not available
            return () => clearTimeout(timer);
        }
    }, [userRoles]);

    const arr = [
        { label: "Admins", color: theme.palette.primary.light },
        { label: "Users", color: theme.palette.primary.main },
        { label: "Pending Invites", color: theme.palette.warning.main }
    ];

    const chartData = useMemo(() => ({
        a: userRoles?.admins,
        b: userRoles?.users,
        c: userRoles?.pendingInvites
    }), [userRoles]);

    const graphColors = [
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.warning.main
    ];

    return (
        <MyCard>
            <GraphTitle showTimeMenu={false} title={'User Role Distribution'} />
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {loading ? (
                    <GraphSkelton height={280} />
                ) : showNoData ? (
                    <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Data Available</Box>
                ) : (
                    <PieChart
                        chartData={chartData}
                        width={400}
                        height={230}
                        graphColors={graphColors}
                        totalValue={userRoles?.admins + userRoles?.users + userRoles?.pendingInvites}
                        radius={90}
                        left={"43.5%"}
                        top={'80px'}
                        showLabels={true}
                        totalText={"Total"}
                        showPercentage={false}
                    />
                )}
            </Box>
            {!loading && !showNoData && <CustomLegend arr={arr} />}
        </MyCard>
    );
};

export default UserRoleDistribution;
