import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import styles from "./subscription.module.scss";

const cardBrandIcons = {
    visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
    mastercard: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
};

const PaymentMethodCard = ({ currentMethod }) => {
    if (!currentMethod) return null;

    const { brand, last4 } = currentMethod;
    const maskedNumber = "•••• •••• •••• " + last4;
    const cardIcon = cardBrandIcons[brand?.toLowerCase()] || cardBrandIcons["visa"]; // Default to Visa if brand not found

    return (
        <Card className={styles.subscriptionDetailCard}>
            <Typography variant="body1" fontWeight={'bold'}>Payment Method</Typography>
            <CardContent className={styles.paymentContent}>

                <Box className={styles.cardInfo}>
                    {/* Card Brand Icon */}
                    <img src={cardIcon} alt={brand} className={styles.cardBrandIcon} />

                    {/* Masked Card Number */}
                    <Typography variant="h6" className={styles.cardNumber}>
                        {maskedNumber}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PaymentMethodCard;
