import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { devicesActions } from '../../../../redux/slice/devices.slice';
import MyCard from '../../../shared/Card/Card';
import text from '../../../shared/css/text.module.scss';
import styles from '../device.module.scss';
import mapStyles from './deviceMap.module.scss';
import { getMapTooltip } from './helper';

const MapboxMap = ({ markers, center, buttonTxt, tooltipData, isFullScreen, showNetworkId = false }) => {
    const mapContainerRef = useRef(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClickDetails = useCallback((device) => {
        dispatch(devicesActions.actions.setSelectedDevice(device));
        navigate(`/single-dashboard/${device?._id}`);
    }, [dispatch, navigate])

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: center ? center.split(',').map(Number).reverse() : [60, 30],
            zoom: 10,
        });



        const addMarkers = () => {

            if (markers?.length) {

                markers.forEach(marker => {
                    const el = document.createElement('div');
                    el.innerHTML = marker.html;

                    const tooltipContainer = document.createElement('div');
                    tooltipContainer.className = styles.tooltipTopology;
                    tooltipContainer.innerHTML = getMapTooltip(marker, showNetworkId)

                    const popup = new mapboxgl.Popup({
                        offset: 15,
                        closeButton: true,
                        closeOnClick: false,
                    }).setDOMContent(tooltipContainer);


                    new mapboxgl.Marker(el)
                        .setLngLat(marker.coordinates)
                        .setPopup(popup) // Attach the custom popup to the marker
                        .addTo(map);

                    const button = tooltipContainer.querySelector(`.${mapStyles.topologyButton}`);
                    if (button) {
                        button.addEventListener('click', () => {
                            handleClickDetails(marker?.device);
                        });
                    }
                });
            }
        };

        addMarkers();
        return () => {
            map.remove();
        };
    }, [theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main, markers, center, tooltipData, isFullScreen, showNetworkId, handleClickDetails]); // Include dependencies

    return (
        <MyCard padding={2}>
            <Typography className={text.heading2}>Live Devices Location</Typography>
            <div className={styles.map} ref={mapContainerRef} />
        </MyCard>
    );
}

export default memo(MapboxMap);
