import styles from './walkthrough.module.scss';
export const walkthroughsContent = {
    deviceWalkthrough: {
        steps: [
            {
                target: ".register_device",
                content: (
                    <div className={styles.walkthroughContainer}>
                        <h3 className={styles.title}>Device Enrollment</h3>
                        <video width="350" controls autoPlay playsInline className={styles.video}>
                            <source src={"https://storage.googleapis.com/bdata-pub/demos/Bdata%20Device%20Enrollement%20-%20Voice%20Over.mp4"} type="video/mp4" />
                        </video>
                    </div>
                ),
                disableBeacon: true
            }
        ]
    },
    networkWalkthrough: {
        steps: [
            {
                target: ".register_network",
                content: (
                    <div className={styles.walkthroughContainer}>
                        <h3 className={styles.title}>Network Enrollment</h3>
                        <video width="350" controls autoPlay playsInline className={styles.video}>
                            <source src={"https://storage.googleapis.com/bdata-pub/demos/Bdata%20Network%20Enrollment%20-%20Made%20with%20Clipchamp.mp4"} type="video/mp4" />
                        </video>
                    </div>
                ),
                disableBeacon: true
            }
        ]
    },
    singleDeviceWalkthrough: {
        steps: [
            {
                target: ".register_device",
                content: (
                    <div className={styles.walkthroughContainer}>
                        <h3 className={styles.title}>Device Enrollment</h3>
                        <video width="350" controls autoPlay playsInline className={styles.video}>
                            <source src={"https://storage.googleapis.com/bdata-pub/demos/Bdata%20Device%20Enrollement%20-%20Voice%20Over.mp4"} type="video/mp4" />
                        </video>
                    </div>
                ),
                disableBeacon: true
            }
        ]
    }
}