import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  DELETE_USER,
  UPDATE_USER
} from "../../../../redux/types/auth.types";
import logout_img from "../../../../static/assets/icons/logout.png";
import styles from "./UserInfo.module.scss";

const UserInfo = ({ month, day, year, country, userId }) => {
  const dispatch = useDispatch();
  const [logoutOpen, setLogoutOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      month: month || "",
      day: day || "",
      year: year || "",
      country: country || "",
    },
  });

  useEffect(() => {
    setValue("month", month);
    setValue("day", day);
    setValue("year", year);
    setValue("country", country);
  }, [month, day, year, country, setValue]);

  const handleLogoutOpen = () => {
    setLogoutOpen(true);
  };

  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };

  const handleLogout = () => {
    dispatch({ type: DELETE_USER, payload: userId });
    setLogoutOpen(false);
  };

  const handleDelete = () => {
    handleLogoutOpen();
  };

  const onSubmit = (data) => {
    const dob = `${data.year}-${data.month}-${data.day}`;
    const formData = {
      ...data,
      dob,
    };
    dispatch({ type: UPDATE_USER, payload: formData });
  };

  const currentYear = new Date().getFullYear();
  const years = [...Array(100).keys()].map((year) => currentYear - year);

  return (
    <>
      <Box className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={styles.label}>
                Birthday
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Controller
                    name="month"
                    control={control}
                    rules={{ required: "Month is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        placeholder="Month"
                        fullWidth
                        variant="outlined"
                        error={!!errors.month}
                        helperText={errors.month ? errors.month.message : ""}
                        InputProps={{
                          sx: { height: 40, borderRadius: 2 },
                        }}
                      >
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="day"
                    control={control}
                    rules={{ required: "Day is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        placeholder="Day"
                        fullWidth
                        variant="outlined"
                        error={!!errors.day}
                        helperText={errors.day ? errors.day.message : ""}
                        InputProps={{
                          sx: { height: 40, borderRadius: 2 },
                        }}
                      >
                        {[...Array(31).keys()].map((day) => (
                          <MenuItem key={day + 1} value={day + 1}>
                            {day + 1}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="year"
                    control={control}
                    rules={{ required: "Year is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        placeholder="Year"
                        fullWidth
                        variant="outlined"
                        error={!!errors.year}
                        helperText={errors.year ? errors.year.message : ""}
                        InputProps={{
                          sx: { height: 40, borderRadius: 2 },
                        }}
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item mt={2} xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                type="submit"
                variant="contained"
                className={`${styles.saveButton} ${styles.halfRoundButton}`}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>

      </Box>
      <br />
      <Box className={styles.formContainer}>
        <Grid container>
          <Grid item xs={12} className={styles.section}>
            <Typography variant="subtitle1" className={styles.label}>
              Delete Your Account
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className={styles.deleteInfo}
            >
              When you delete your account, you lose access to To Front account
              services, and we permanently delete your personal data. You can
              cancel the deletion for 14 days.
            </Typography>

          </Grid>
          <Grid mt={2} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              className={`${styles.deleteButton} ${styles.halfRoundButton}`}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={logoutOpen}
          onClose={handleLogoutClose}
          PaperProps={{ className: styles.dialogPaper }}
        >
          <Box className={styles.dialogContent}>
            <img src={logout_img} alt="logout" className={styles.dialogImage} />
            <Typography variant="h6" className={styles.dialogTitle}>
              Oh No! You’re Leaving...
            </Typography>
            <Typography variant="body1" className={styles.dialogText}>
              Are You Sure?
            </Typography>
            <DialogActions className={styles.dialogActions}>
              <Button onClick={handleLogoutClose} className={styles.cancelButton}>
                Nah, Just Kidding
              </Button>
              <Button onClick={handleLogout} className={styles.confirmButton}>
                Yes, Delete My Account Permanently
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </>

  );
};

export default UserInfo;
