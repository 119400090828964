import apiInterceptor from "./Interceptors/ApiInterceptor";
import authInterceptor from "./Interceptors/AuthInterceptor"

export const fetchGroupsService = async () => {
  try {
    const response = await apiInterceptor.get("/groups/get-groups");
    return response?.data?.groups;
  } catch (error) {
    throw error;
  }
};

export const createGroupService = async (payload) => {
  try {
    const response = await apiInterceptor.post("/groups/create-group", payload);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const getUsersService = async (payload) => {
  try {
    const response = await authInterceptor.get("/get-users", payload);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getInvitesService = async (payload) => {
  try {
    const response = await authInterceptor.get("/get-invites", payload);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const addUserToGroupService = async (paylaod) => {
  try {
    const response = await apiInterceptor.post("/groups/add-user", paylaod);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const addDeviceToGroupService = async (groupId, deviceIds) => {
  try {
    const response = await apiInterceptor.post("/groups/add-device", {
      groupId,
      deviceIds,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const removeUserFromGroupService = async (groupId, userId) => {
  try {
    const response = await apiInterceptor.put("/groups/remove-user", {
      groupId,
      userId,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const removePolicyFromGroupService = async (groupId, policyId) => {
  try {
    const response = await apiInterceptor.put("/groups/remove-policy", {
      groupId,
      policyId,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const removeDeviceFromGroupService = async (groupId, deviceId) => {
  try {
    const response = await apiInterceptor.put("/groups/remove-device", {
      groupId,
      deviceId,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const removeAlertPolicyFromGroupService = async (groupId, policyId) => {
  try {
    const response = await apiInterceptor.put("/groups/remove-alert-policy", {
      groupId,
      policyId,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
}

export const inviteUserService = async (payload) => {
  try {
    const response = await authInterceptor.post("/invite-user", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInviteService = async (payload) => {
  try {
    const response = await authInterceptor.post("/delete-invites", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const resendInviteService = async (payload) => {
  try {
    const response = await authInterceptor.post("/resend-invite", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createUserService = async (payload) => {
  try {
    const response = await authInterceptor.post("/create-user", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const addAppToGroupService = async (groupId, appIds) => { // Add this function
  try {
    const response = await apiInterceptor.post("/groups/add-app", {
      groupId,
      appIds,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const blacklistAppInGroupService = async (groupId, appIds) => {
  try {
    const response = await apiInterceptor.post("/groups/blacklist-app", {
      groupId,
      appIds,
    });
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
}

export const removeGroupService = async (groupId) => {
  try {
    const response = await apiInterceptor.delete(`/groups/remove/${groupId}`);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const validateTokenService = async () => {
  try {
    const response = await authInterceptor.post("/validate-access-token");
    return response?.data
  } catch (error) {
    return error?.response?.data
    // throw error;
  }
}

export const updateUserSettingsServ = async (payload) => {
  try {
    console.log("payload", payload);
    const response = await authInterceptor.put("/update-user-settings", payload);
    return response?.data
  } catch (error) {
    return error?.response?.data
    // throw error;
  }
}