import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff'
import { Box } from '@mui/material'
import MyCard from 'components/shared/Card/Card'
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton'
import theme from 'components/utils/theme'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { alertsActions } from '../../../redux/slice/alerts.slice'
import { uiActions } from '../../../redux/slice/ui.slice'
import styles from '../../shared/Tables/MyTable/myTable.module.scss'
import PieChart from '../Device/PieChart'
import CustomLegend from './CustomLegend'
import GraphTitle from './GraphTitle'
import { processChartData } from './helper'

const ThreatComparison = ({ alerts }) => {

    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        const hasData = alerts?.some(alert => alert.data.length > 0);
        if (hasData) {
            setNoData(false);
            setLoading(false);
        }
        const timer = setTimeout(() => {
            setNoData(!hasData);
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [alerts]);

    const arr = [
        {
            label: "High Alerts",
            color: theme.palette.error.main
        },
        {
            label: "Medium Alerts",
            color: theme.palette.warning.main
        },
        {
            label: "Low Alerts",
            color: theme.palette.success.main
        },
    ]
    const threats = useMemo(() => processChartData(alerts), [alerts]);

    const graphColors = [
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.success.main,
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickChart = (data) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        switch (data) {
            case 'a':
                dispatch(alertsActions.actions.setSelectedLevel("High"));
                break;
            case 'b':
                dispatch(alertsActions.actions.setSelectedLevel("Medium"));
                break;
            case 'c':
                dispatch(alertsActions.actions.setSelectedLevel("Low"));
                break;
            default:
        }
        navigate("/");
    }

    return (
        <MyCard>
            <GraphTitle showTimeMenu={false} title={'Alerts Comparison'} />
            {loading ? (
                <GraphSkelton />
            ) : noData ? (
                <Box className={styles.noData}><ContentPasteOffIcon /> No Data Available</Box>
            ) : (
                <>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <PieChart
                            chartData={threats?.chartData}
                            width={400}
                            height={230}
                            graphColors={graphColors}
                            totalValue={threats?.total}
                            radius={90}
                            left={'43.5%'}
                            top={'80px'}
                            showLabels={true}
                            totalText={'Total'}
                            showPercentage={false}
                            handleClick={handleClickChart}
                        />
                    </Box>
                    <CustomLegend arr={arr} />
                </>
            )}
        </MyCard>
    );
}

export default ThreatComparison;