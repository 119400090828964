import { generateCsvDevices, generateCsvGroups, generateCsvNetworks, generateHtmlTable } from "components/utils/accessManagement";
import toast from "react-hot-toast";

export const handleDownloadCsv = ({ rows, columns, name, isGroup = false }) => {
  if (!rows?.length) {
    return toast.error("No data available for export")
  }
  const currentDate = new Date().toISOString().split("T")[0];
  const csvContent = isGroup ? generateCsvGroups(rows, columns) : generateCsvDevices(rows, columns)
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${name}_${currentDate}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const handlePrintCsv = ({ rows, columns, name, isGroup = false }) => {
  if (!rows?.length) {
    return toast.error("No data available for export")
  }
  const currentDate = new Date().toISOString().split("T")[0];
  const csvContent = isGroup ? generateCsvGroups(rows, columns) : generateCsvDevices(rows, columns)
  const tableHtml = generateHtmlTable(csvContent);
  const printWindow = window.open("", "", "height=600,width=800");
  printWindow.document.write(`<html><head><title>${name}_${currentDate}</title>`);
  printWindow.document.write(
    "<style>body { font-family: Arial, sans-serif; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } th { background-color: #f2f2f2; }</style>"
  );
  printWindow.document.write("</head><body>");
  printWindow.document.write(tableHtml);
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
};


export const handleDownloadNetworkCsv = ({ rows, columns, name }) => {
  if (!rows?.length) {
    return toast.error("No data available for export")
  }
  const currentDate = new Date().toISOString().split("T")[0];
  const csvContent = generateCsvNetworks(rows, columns)
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${name}_${currentDate}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const handlePrintNetwork = ({ rows, columns, name }) => {
  if (!rows?.length) {
    return toast.error("No data available for export")
  }
  const currentDate = new Date().toISOString().split("T")[0];
  const csvContent = generateCsvNetworks(rows, columns)
  const tableHtml = generateHtmlTable(csvContent);
  const printWindow = window.open("", "", "height=600,width=800");
  printWindow.document.write(`<html><head><title>${name}_${currentDate}</title>`);
  printWindow.document.write(
    "<style>body { font-family: Arial, sans-serif; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } th { background-color: #f2f2f2; }</style>"
  );
  printWindow.document.write("</head><body>");
  printWindow.document.write(tableHtml);
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
};
