import authInterceptor from "./Interceptors/AuthInterceptor";

export const getSubscriptionPlansServ = async (payload) => {
    try {
        const response = await authInterceptor.get(`/subscription/get-subscription-plans`, {
            params: payload
        });
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const getPaymentMethodServ = async () => {
    try {
        const response = await authInterceptor.get(`/payment/get-customer-payment-method`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createStripePaymentServ = async (payload) => {
    try {
        const response = await authInterceptor.post(`/payment/create-stripe-payment`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const cancelSubscriptionServ = async () => {
    try {
        const response = await authInterceptor.delete(`/payment/cancel-subscription`);
        return response?.data
    } catch (error) {
        throw error;
    }
};