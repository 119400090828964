import { Card, Grid } from "@mui/material";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { alertsActions } from "../../../redux/slice/alerts.slice";
import { GET_MULTI_ALERTS } from "../../../redux/types/multi.types";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import table from "../../shared/Tables/table.module.scss";
import { selectMenuOptions } from "../Device/helper";
import { MULTI_ALERT_DOWNLOAD_COLS, multiAlertColumns } from "./helper";

const MultiAlerts = () => {
    const [alertSearchTerm, setalertSearchTerm] = useState("");
    const alerts = useSelector(alertsActions.selectors.getMultiAlerts)

    const selectedOption = useSelector(alertsActions.selectors.getSelectedLevel)
    const dispatch = useDispatch();

    const { type } = useParams();
    useEffect(() => {
        dispatch({ type: GET_MULTI_ALERTS })
    }, [dispatch]);


    const handleAlertSearch = (term) => {
        setalertSearchTerm(term);
    };

    const filteredAlerts = useMemo(() => {
        const filteredBySearch = alerts.filter((alert) => {
            if (!alertSearchTerm) return true;

            const regex = new RegExp(alertSearchTerm, "i");
            return Object.values(alert).some((value) => regex.test(value));
        });

        const filteredByLevel = filteredBySearch.filter((alert) => {
            if (selectedOption === "All" || !selectedOption) return true;
            return alert.level === selectedOption;
        });

        return filteredByLevel.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }, [alerts, alertSearchTerm, selectedOption]);


    const handleChange = (event) => {
        dispatch(alertsActions.actions.setSelectedLevel(event?.target?.value))
    }


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} xl={12} className={table.tableContainer}>
                    <CustomTitle title={"Alerts Count"} count={filteredAlerts?.length} />
                    <Card>
                        <MyTableToolbar
                            placeHolder={"Search For Alerts"}
                            onSearch={handleAlertSearch}
                            onDownload={() =>
                                handleDownloadCsv({ rows: filteredAlerts, columns: MULTI_ALERT_DOWNLOAD_COLS, name: `All_Alerts` })
                            }
                            onPrint={() => handlePrintCsv({ rows: filteredAlerts, columns: MULTI_ALERT_DOWNLOAD_COLS, name: `All_Alerts` })}
                            isAlerts={true}
                            selectMenuOptions={selectMenuOptions}
                            selectedOption={selectedOption}
                            handleSelect={handleChange}
                        />
                        <MyTable
                            checkboxSelection={false}
                            columns={multiAlertColumns}
                            rows={filteredAlerts}
                        />
                    </Card>
                </Grid>
            </Grid>
            <br />
        </div>
    );
};

export default MultiAlerts;
