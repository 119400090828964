import { Box } from "@mui/material";
import AuthenticationSettings from "components/modules/Account/AuthenticationSettings/AuthenticationSettings";
import NotificationsTab from "components/modules/Account/NotificationsTab/NotificationsTab";
import ProfileTab from "components/modules/Account/ProfileTab/ProfileTab";
import Subscription from "components/modules/Subscription/Subscription";
import CustomNavTabs from "components/shared/CustomNavTabs";
import { getMonthDayYear, settingNavItems } from "components/utils/accounts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/slice/auth.slice";
import { uiActions } from "../../redux/slice/ui.slice";
import styles from "./Account.module.scss";

const Account = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const isTwoFaEnabled = currentUser?.isTwoFaEnabled;
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    dispatch(uiActions.actions.setSelectedNav("Setting"));
  }, [dispatch]);

  const { year, month, day } = getMonthDayYear(currentUser?.dob);

  const handleSelectedTab = (newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className={styles.accountContainer}>
      {currentUser?.role === "admin" && (
        <Box className={styles.tabs}>
          <CustomNavTabs
            selectedTab={selectedTab}
            handleSelectedTab={handleSelectedTab}
            navItems={settingNavItems}
          />
        </Box>
      )}
      {selectedTab === 0 && (
        <ProfileTab
          currentUser={currentUser}
          month={month}
          day={day}
          year={year}
          country={currentUser.country}
          userId={currentUser._id}
        />
      )}
      {selectedTab === 1 && currentUser?.role === "admin" && (
        <NotificationsTab isTwoFaEnabled={isTwoFaEnabled} />
      )}
      {selectedTab === 2 && (
        <AuthenticationSettings isTwoFaEnabled={isTwoFaEnabled} />
      )}
      {selectedTab === 3 && (
        <Subscription cols={12} changePlanLink={"/manage-plans"} />
      )}
    </Box>
  );
};

export default Account;
