import { SUBSCRIPTION_STATUS } from "components/utils/enums";
import toast from "react-hot-toast";
import { call, put, takeEvery } from "redux-saga/effects";
import { cancelSubscriptionServ, createStripePaymentServ, getPaymentMethodServ, getSubscriptionPlansServ } from "../services/subscription.service";
import { authActions } from "../slice/auth.slice";
import { subscriptionActions } from "../slice/subscription.slice";
import { CANCEL_SUBSCRIPTION_PLAN, CREATE_STRIPE_PAYMENT, GET_PAYMENT_METHOD, GET_SUBSCRIPTION_PLANS } from "../types/subscription.types";

export function* getSubscriptionPlans(action) {
  try {
    const response = yield getSubscriptionPlansServ(action.payload);
    if (response?.subscriptionPlans) {
      yield put(subscriptionActions.actions.setSubscriptionPlans(response?.subscriptionPlans));
      yield put(subscriptionActions.actions.setIsTrailAvailable(response?.isTrailAvailable));

    }
    if (response?.discount) {
      yield put(subscriptionActions.actions.setSubscriptionDiscount(response?.discount));
    }
    else {
      yield put(subscriptionActions.actions.setSubscriptionDiscount({}));
    }
  } catch (err) {
    console.log("err", err);
  }
}

// eslint-disable-next-line require-yield
export function* createStripePayment(action) {
  try {
    const paymentResponse = yield call(() =>
      toast.promise(
        createStripePaymentServ(action.payload),
        {
          loading: "Processing payment... Please wait.",
          success: "Payment successful! Your subscription is now active. 🎉",
          error: (err) => {
            return err?.response?.data?.message || "Payment failed. Please try again or contact support.";
          }
        }
      )
    );

    if (paymentResponse) {
      yield put(subscriptionActions.actions.setSelectedPricingTab(2));
      yield put(authActions.actions.setSubscriptionStatus(SUBSCRIPTION_STATUS.active));
    }
  } catch (err) {
    console.log("err", err);
  }
}


export function* cancelSubscription() {
  try {
    const canceledSubscription = yield call(() =>
      toast.promise(
        cancelSubscriptionServ(),
        {
          loading: "Canceling your subscription... Please wait.",
          success: (resp) => {
            return resp?.message
          },
          error: (err) => {
            return err?.response?.data?.message ||
              "We couldn't process your cancellation at the moment. Please try again or contact support for assistance.";
          }
        }
      )
    );

    if (canceledSubscription) {
      const paymentMethod = yield getPaymentMethodServ();
      if (paymentMethod) {
        yield put(subscriptionActions.actions.setCurrentPaymentMethod(paymentMethod?.data));
      }
    }

  } catch (err) {
    console.log("Error while canceling subscription:", err);
  }
}

export function* getPaymentMethod() {
  try {
    const paymentMethod = yield getPaymentMethodServ();
    if (paymentMethod) {
      yield put(subscriptionActions.actions.setCurrentPaymentMethod(paymentMethod?.data));
    }
  } catch (err) {
    console.log("err", err);
  }
}


export function* watchSubscriptionAsync() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
  yield takeEvery(CREATE_STRIPE_PAYMENT, createStripePayment);
  yield takeEvery(CANCEL_SUBSCRIPTION_PLAN, cancelSubscription);
  yield takeEvery(GET_PAYMENT_METHOD, getPaymentMethod);
}
