import MyCard from 'components/shared/Card/Card';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import TableSkeleton from 'components/shared/Skeletons/TableSkeleton';
import theme from 'components/utils/theme';
import { memo, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { analyticsActions } from '../../../redux/slice/analytics.slice';
import { GET_ANOMALIES } from '../../../redux/types/multi.types';
import CustomLegend from './CustomLegend';
import GraphTitle from './GraphTitle';
import { anomalyRangeOptions } from './helper';
import { useNavigate } from 'react-router-dom';
import { uiActions } from '../../../redux/slice/ui.slice';
import { alertsActions } from '../../../redux/slice/alerts.slice';
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';
import styles from '../../shared/Tables/MyTable/myTable.module.scss'


const ScatterChart = () => {
    const dispatch = useDispatch();
    const [selectedRange, setSelectedRange] = useState('1year');
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);

    const anomaliesData = useSelector(analyticsActions.selectors.getAnomalies);
    const navigate = useNavigate();

    const handleClickChart = (level) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        switch (level) {
            case 'Critical Alerts':
                dispatch(alertsActions.actions.setSelectedLevel("High"));
                break;
            case 'Moderate Alerts':
                dispatch(alertsActions.actions.setSelectedLevel("Medium"));
                break;
            case 'No Alerts':
                dispatch(alertsActions.actions.setSelectedLevel("Low"));
                break;
            default:
                break;
        }
        navigate("/");
    };

    useEffect(() => {
        setLoading(true);
        setNoData(false);

        dispatch({
            type: GET_ANOMALIES,
            payload: { range: selectedRange },
        });
    }, [dispatch, selectedRange])


    useEffect(() => {
        if (anomaliesData) {
            const hasData = anomaliesData?.high?.length > 0 || anomaliesData?.medium?.length > 0 || anomaliesData?.low?.length > 0;

            if (hasData) {
                setLoading(false);
                setNoData(false);
            }
            else {
                const timeout = setTimeout(() => {
                    setNoData(true);
                    setLoading(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        }
    }, [anomaliesData]);


    const arr = [
        { label: "Critical Alerts", color: theme.palette.error.main },
        { label: "Moderate Alerts", color: theme.palette.warning.main },
        { label: "No Alerts", color: theme.palette.success.main },
    ];

    const options = {
        chart: {
            type: 'scatter',
            toolbar: { show: false },
            zoom: { type: 'xy' },
            events: {
                click: (event, chartContext, config) => {
                    const { seriesIndex } = config;
                    if (seriesIndex !== -1) {
                        const seriesName = series[seriesIndex]?.name;
                        handleClickChart(seriesName);
                    }
                },
            },
        },
        legend: { show: false },
        colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main],
        grid: {
            show: true,
            borderColor: '#e0e0e0',
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: true } },
        },
    };

    const series = useMemo(() => [
        { name: 'Critical Alerts', data: anomaliesData?.high, color: theme.palette.error.main, markers: { shape: 'circle', size: 8 } },
        { name: 'Moderate Alerts', data: anomaliesData?.medium, color: theme.palette.warning.main, markers: { shape: 'triangle', size: 8 } },
        { name: 'No Alerts', data: anomaliesData?.low, color: theme.palette.success.main, markers: { shape: 'square', size: 8 } },
    ], [anomaliesData]);


    const [isFullScreen, setIsFullScreen] = useState(false);
    const handleToggleScreen = () => setIsFullScreen((prevState) => !prevState);

    return (
        <>
            <MyCard height={450}>
                <GraphTitle
                    setTime={setSelectedRange}
                    time={selectedRange}
                    setSelectedRange={setSelectedRange}
                    showFullScreenBtn={true}
                    handleClickFullScreen={handleToggleScreen}
                    title={'Anomaly Detection'}
                    menuOptions={anomalyRangeOptions}
                />
                {loading ? (
                    <GraphSkelton height={350} />
                ) : noData ? (
                    <Box className={styles.noData}><ContentPasteOffIcon /> No Data Available</Box>

                ) : (
                    <Chart options={options} series={series} type="scatter" height={325} />
                )}
                <CustomLegend arr={arr} />
            </MyCard>
            <CustomDialog fullScreen={true} title={'Anomaly Detection'} isOpen={isFullScreen} handleToggle={handleToggleScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    {loading ? (
                        <TableSkeleton rows={4} cols={5} />
                    ) : noData ? (
                        <Box className={styles.noData}><ContentPasteOffIcon /> No Data Available</Box>

                    ) : (
                        <Chart options={options} series={series} type="scatter" height={'490px'} />
                    )}
                    <CustomLegend arr={arr} />
                </Grid>
            </CustomDialog>
        </>
    );
};

export default memo(ScatterChart);
