import io from 'socket.io-client';
import { insertIdToArray } from 'components/utils';
import { TOPICS } from 'components/utils/enums';
import { applicationsActions } from '../slice/applications.slice';
import { consoleActions } from '../slice/console.slice';
import { devicesActions } from '../slice/devices.slice';
import { networksActions } from '../slice/networks.slice';
import { packagesActions } from '../slice/packages.slice';
import { portsActions } from '../slice/ports.slice';
import { processesActions } from '../slice/processes.slice';
import { systemActions } from '../slice/system.slice';
import { store } from '../store';
import { filesActions } from '../slice/files.slice';
import { fimActions } from '../slice/fim.slice';
import { deviceUsersActions } from '../slice/deviceUsers.slice';
import { parseAndMergeData } from 'components/utils/socket.util';
import { alertsActions } from '../slice/alerts.slice';
import { networkDeviceActions } from '../slice/networkDevice.slice';

let socket;

export const getSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_SOCKET_URL);
    console.log('Socket connected');
  }
  return socket;
};

export const initializeSocket = () => {
  socket = getSocket();
}

export const setupDashboardSocket = (dispatch) => {
  var state = {}
  socket.on(TOPICS.PROCESSES, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      dispatch(processesActions.actions.setProcesses(insertIdToArray(JSON.parse(data?.message)?.result)))
    }
  });

  socket.on(TOPICS.SYSTEM, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      dispatch(systemActions.actions.setSystem(JSON.parse(data?.message)?.result))
    }
  });


  socket.on(TOPICS.SYSTEM_PACKAGES, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(packagesActions.actions.setPackages(insertIdToArray(JSON.parse(JSON.parse(data?.message)?.result))))
      }
      else {
        dispatch(packagesActions.actions.setPackages(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
    }
  });

  socket.on(TOPICS.APPLICATIONS, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(applicationsActions.actions.setApplications(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
      else {
        dispatch(applicationsActions.actions.setApplications(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
    }
  });

  socket.on(TOPICS.NETWORK_INTERFACES, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      dispatch(networksActions.actions.setNetworks(insertIdToArray(JSON.parse(data?.message)?.result)))
    }
  });

  socket.on(TOPICS.PORTS, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      dispatch(portsActions.actions.setPorts(insertIdToArray(JSON.parse(data?.message)?.result)))
    }
  });

  socket.on(TOPICS.ALL_APPS, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(applicationsActions.actions.setCombineApps(insertIdToArray(JSON.parse(JSON.parse(data?.message)?.result))))
      } else {
        dispatch(applicationsActions.actions.setCombineApps(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
    }
  });

  socket.on(TOPICS.USERS, (data) => {
    state = store.getState();
    const userData = JSON.parse(data?.message);
    if (state?.devices?.selectedDevice?.uid === userData?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(deviceUsersActions.actions.setdeviceUsers(insertIdToArray(userData?.result)))
      }
      else {
        // dispatch(deviceUsersActions.actions.setdeviceUsers(insertIdToArray(userData?.result)))
      }
    }
  });

  socket.on("SINGLE_DEVICE", (data) => {
    dispatch(devicesActions.actions.setCurrentDevice(JSON.parse(data?.message)))
  });
};

export const setupMultiDashboardSocket = (dispatch) => {

};

export const setupSingleAppsSocket = (dispatch) => {
  var state = {};
  socket.on(TOPICS.SYSTEM_PACKAGES, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(packagesActions.actions.setPackages(insertIdToArray(JSON.parse(JSON.parse(data?.message)?.result))))
      }
      else {
        dispatch(packagesActions.actions.setPackages(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
    }
  });
  socket.on(TOPICS.APPLICATIONS, (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (state?.devices?.selectedDevice?.systemDetails?.os === 'Windows') {
        dispatch(applicationsActions.actions.setApplications(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
      else {
        dispatch(applicationsActions.actions.setApplications(insertIdToArray(JSON.parse(data?.message)?.result)))
      }
    }
  });
};

export const setupMultiAppsSocket = (dispatch) => {
  var state = {};

  socket.on(TOPICS.APPLICATIONS, (data) => {
    state = store.getState();
    parseAndMergeData(data, state?.applications?.multiApps, dispatch, applicationsActions.actions.setMultiApps);
  });

  socket.on(TOPICS.SYSTEM_PACKAGES, (data) => {
    state = store.getState();
    parseAndMergeData(data, state?.packages?.multiPackages, dispatch, packagesActions.actions.setMultiPackages);
  });
};

export const setUpDeviceSocket = (dispatch) => {
  if (!socket) return;
  socket.on(TOPICS.DEVICES, (data) => {
    dispatch(devicesActions.actions.setDevices(JSON.parse(data?.message)?.registeredDevice))
  });
};

export const setUpDeviceUsersSocket = (dispatch) => {
  if (!socket) return;
  var state = {}
  state = store.getState();
  socket.on(TOPICS.USERS, (data) => {
    const userData = JSON.parse(data?.message);
    if (state?.devices?.selectedDevice?.uid === userData?.uid) {
      dispatch(deviceUsersActions.actions.setdeviceUsers(insertIdToArray(userData?.result)))
    }
  });
};



export const setupConsoleSocket = (dispatch) => {
  if (!socket) return;
  var state = {}
  socket.on(TOPICS.EXECUTE_RESPONSE, (data) => {
    state = store.getState();
    if (state?.console?.consoleType === 'multi') {
      dispatch(consoleActions.actions.setConsoleResponse(JSON.parse(data?.message)));
    }
    else {
      if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
        dispatch(consoleActions.actions.setConsoleResponse(JSON.parse(data?.message)));
      }
    }
  });
};


export const setUpFimSocket = (dispatch) => {
  if (!socket) return;
  var state = {};
  socket.on("FIM", (data) => {
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === JSON.parse(data?.message)?.uid) {
      if (JSON.parse(data?.message)?.["Events:"]?.length) {
        dispatch(fimActions.actions.setFim(insertIdToArray(JSON.parse(data?.message)?.["Events:"])))
      }
    }
  });
};


export const setUpNetworkDevicesocket = (dispatch) => {
  if (!socket) return;
  socket.on("NETWORK_DEVICES", (data) => {
    dispatch(networkDeviceActions.actions.setNetworkDevices(JSON.parse(data?.message)))
  });
};

export const setUpSingleNetworksocket = (dispatch) => {
  if (!socket) return;
  socket.on("SINGLE_NETWORK_RESP", (data) => {
    dispatch(networkDeviceActions.actions.setSelectedNetwork(JSON.parse(data?.message)))
  });
};


export const getDevicesSocket = (data) => {
  socket.emit("GET_DEVICES", data)
}


export const getNetowrkDevicesSocket = (data) => {
  socket.emit("GET_NETWORK_DEVICES", data)
}


export const setupFilesSocket = (dispatch) => {
  if (!socket) return;
  socket.on("FILES_DATA", (data) => {
    dispatch(filesActions.actions.setFiles(JSON.parse(data?.message)));
  });
}

export const setUpAlertsSocket = (dispatch) => {
  if (!socket) return;
  var state = {};
  socket.on("FIM_ALERTS", (data) => {
    const alertsData = JSON.parse(data)
    state = store.getState();
    if (state?.devices?.selectedDevice?.uid === alertsData?.uid) {
      if (alertsData?.result?.length) {
        dispatch(alertsActions.actions.setFileAlerts(insertIdToArray(alertsData?.result)))
      }
    }
  });
};

export const getFilesSocket = (data) => {
  socket.emit("GET_FILES", data)
}

export const getSingleDevicesSocket = (data) => {
  socket.emit("GET_SINGLE_DEVICE", data)
}

export const sendToSocket = (topic, data) => {
  socket.emit(topic, data)
}

export const executeCommand = (action, type, uid, os) => {
  const payload = {
    type,
    action,
    uid,
    os
  }
  socket.emit(TOPICS.EXECUTE_COMMAND, payload)
}


export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    console.log('Socket disconnected');
    socket = null; // Clear the reference to enforce creating a new socket next time
  }
};

export const unSubscribeSocketTopics = (topics) => {
  if (socket && topics && topics.length > 0) {
    topics.forEach(topic => {
      socket.off(topic);
    });
  }
};

export default getSocket;
