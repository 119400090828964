import apiInterceptor from "./Interceptors/ApiInterceptor";

export const getEmployeeServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-employees`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const createEmployeeServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/create-employee`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateEmployeeServ = async (payload) => {
    try {
        const response = await apiInterceptor.put(`/update-employee`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteEmployeeServ = async (payload) => {
    try {
        const response = await apiInterceptor.delete(`/delete-employees`, {
            data: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}
