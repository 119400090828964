import { ReportGmailerrorred } from '@mui/icons-material'
import { Box, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import CustomButton from 'components/shared/Buttons/CustomButton'
import CustomDialog from 'components/shared/Dialog/CustomDialog'
import theme from 'components/utils/theme'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subscriptionActions } from '../../../redux/slice/subscription.slice'
import { CANCEL_SUBSCRIPTION_PLAN, GET_PAYMENT_METHOD } from '../../../redux/types/subscription.types'
import CurrentPlanCard from '../PricingPlan/CurrentPlanCard'
import PaymentMethodCard from './PaymentMethodCard'
import SubscriptionDetailsCard from './SubscriptionDetailsCard'
import { cancelButtonDetails } from './helper'

const Subscription = ({ cols, changePlanLink }) => {
    const dispatch = useDispatch();
    const selectedPlan = useSelector(subscriptionActions.selectors.getSelectedPlan)
    const isYearly = useSelector(subscriptionActions.selectors.getIsYearlyBilling);
    const currentPaymentMethod = useSelector(subscriptionActions.selectors.getCurrentPaymentMethod);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleOpen = () => {
        setConfirmationOpen(true);
    };

    const handleClose = () => {
        setConfirmationOpen(false);
    }

    useEffect(() => {
        dispatch({ type: GET_PAYMENT_METHOD })
    }, [dispatch]);


    const handleCancelSubscription = () => {
        handleClose();
        dispatch({ type: CANCEL_SUBSCRIPTION_PLAN })
    }


    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={cols}>
                <CurrentPlanCard title={"Current subscription Plan"} handleClickButton={handleOpen} selectedPlan={selectedPlan} isYearly={isYearly} buttonDetails={cancelButtonDetails} />
                <br />
                <SubscriptionDetailsCard paymentMethod={currentPaymentMethod} />
                <br />
                <PaymentMethodCard currentMethod={currentPaymentMethod} />
            </Grid>

            <CustomDialog showTitle={false} isOpen={confirmationOpen} handleToggle={handleClose}>
                <DialogContent>
                    {/* Centered Icon + Message Container */}
                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>

                        {/* Big Warning Icon */}
                        <ReportGmailerrorred sx={{ fontSize: 60, color: "orange" }} />

                        {/* Message */}
                        <Typography variant="h6" fontWeight="bold">
                            Oh No! You're Cancelling Your Subscription ?
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Are You sure!
                        </Typography>
                    </Box>
                </DialogContent>

                {/* Action Buttons */}
                <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
                    <CustomButton
                        padding={"0px 20px"}
                        color={theme.palette.background.default}
                        bgColor={theme.palette.primary.main}
                        hover={theme.palette.info.main}
                        label={"Naah, just kidding"}
                        onClick={handleClose}
                    />
                    <CustomButton
                        padding={"0px 20px"}
                        color={theme.palette.text.primary}
                        bgColor={theme.palette.white.dark}
                        hover={theme.palette.gray.light}
                        label={"Yes! Cancel Subscription"}
                        type={"submit"}
                        onClick={handleCancelSubscription}
                    />

                </DialogActions>
                <br />

            </CustomDialog>

        </Grid>
    )
}

export default Subscription
