import { Add, GroupAdd } from "@mui/icons-material";
import {
  Card,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import CustomButton from "components/shared/Buttons/CustomButton";
import CustomTitle from "components/shared/CustomTitle";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import theme from "components/utils/theme";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ADD_USER_TO_GROUP, DELETE_INVITE, RESEND_INVITE } from "../../../../../redux/types/group.types";
import { INVITE_USER } from "../../../../../redux/types/policy.types";
import { getInvitesColumns, INVITE_DOWNLOAD_COLS, PORTAL_USER_DOWNLOAD_COLS, userColumns } from "./helper";


const Users = ({ user, groups, refreshGroups, currentUser, invites }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const handleOpen = () => {
    if (selectedUsers.length === 0) {
      toast.error("Please choose user first");
      return;
    }
    setOpen(true);
  };

  const handleInviteOpen = () => {
    reset();
    setInviteDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup("");
  };

  const handleInviteClose = () => {
    setInviteDialogOpen(false);
    reset();
  };

  const handleAddUserToGroup = async () => {
    dispatch({
      type: ADD_USER_TO_GROUP,
      payload: { groupId: selectedGroup, userIds: selectedUsers },
    });
    handleClose();
    refreshGroups();
  };

  const handleInviteUser = async (data) => {
    const payload = {
      orgId: currentUser?.orgId,
      email: data?.email.toLowerCase(),
    }
    dispatch({ type: INVITE_USER, payload });
    handleInviteClose();
  };

  const handleSearchChange = (term) => {
    setSearchQuery(term);
  };

  const filteredUsers = user?.filter(
    (user) =>
      user?.username?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.gender?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const [invitesSearchTerm, setInvitesSearchTerm] = useState('');

  const handleSearchInvites = (term) => {
    setInvitesSearchTerm(term)
  }

  const filteredInvites = useMemo(() => {
    if (!invitesSearchTerm) return invites;
    const regex = new RegExp(invitesSearchTerm, "i");
    return invites.filter((network) =>
      Object.values(network).some((value) => regex.test(value))
    );
  }, [invites, invitesSearchTerm]);

  const handleSelectUsers = (ids) => {
    setSelectedUsers(ids);
  }

  const handleResendInvite = (_id) => {
    dispatch({ type: RESEND_INVITE, payload: { _id } })
  }

  const inviteColumns = getInvitesColumns({ handleResendInvite })

  const [selectedInvites, setSelectedInvites] = useState();

  const handleSelectedInvites = (ids) => {
    setSelectedInvites(ids);
  }

  console.log("selectedInvites", selectedInvites)

  const deleteInvites = () => {
    if (!selectedInvites?.length) {
      return toast.error("Please Select Invite to Delete")
    }
    const payload = {
      inviteIds: selectedInvites
    }
    dispatch({ type: DELETE_INVITE, payload })
    console.log("deleting", selectedInvites)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CustomTitle title={"Users"} count={filteredUsers?.length} />
          <br />
          <Card>
            <MyTableToolbar
              placeHolder={"Search Users"}
              btn2Txt={"Add User to Group"}
              btn2Icon={GroupAdd}
              handleBtn2Click={handleOpen}
              onSearch={handleSearchChange}
              onDownload={() => handleDownloadCsv({ rows: filteredUsers, columns: PORTAL_USER_DOWNLOAD_COLS, name: "Portal_Users" })}
              onPrint={() => handlePrintCsv({ rows: filteredUsers, columns: PORTAL_USER_DOWNLOAD_COLS, name: "Portal_Users" })}
            />
            <MyTable
              checkboxSelection={true}
              columns={userColumns}
              handleSelectedRows={handleSelectUsers}
              rows={filteredUsers}
              id={(row) => row?._id}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CustomTitle title={"Invites"} count={invites?.length} />
          <br />
          <Card>
            <MyTableToolbar
              placeHolder={"Search Users"}
              btn1Txt={"Invite User"}
              btn1Icon={Add}
              isUninstall={true}
              handleDeleteClick={deleteInvites}
              handleBtn1Click={handleInviteOpen}
              onSearch={handleSearchInvites}
              onDownload={() => handleDownloadCsv({ rows: filteredInvites, columns: INVITE_DOWNLOAD_COLS, name: "Portal_Invites" })}
              onPrint={() => handlePrintCsv({ rows: filteredInvites, columns: INVITE_DOWNLOAD_COLS, name: "Portal_Invites" })}
            />
            <MyTable
              checkboxSelection={true}
              columns={inviteColumns}
              rows={filteredInvites}
              handleSelectedRows={handleSelectedInvites}
              id={(row) => row?._id}
            />
          </Card>
        </Grid>
      </Grid>
      <br />
      <CustomDialog
        title="Select Group"
        isOpen={open}
        handleToggle={handleClose}
        submitBottomTitle="Invite"
        fullWidth
        noContent
      >
        <DialogContent
          sx={{
            p: 0,
            paddingLeft: "48px",
            paddingRight: "48px",
          }}
        >
          <FormControl fullWidth variant="outlined" margin="normal">
            <Typography variant="subtitle1" fontWeight="500">
              Group
            </Typography>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              sx={{ height: 40, borderRadius: 2 }}
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions
          sx={{
            p: 0,
            paddingRight: "48px",
            paddingBottom: "40px",
            paddingTop: "40px",
          }}
        >
          <CustomButton
            padding={"0 50px"}
            color={theme.palette.primary.main}
            bgColor={theme.palette.background.default}
            hover={theme.palette.gray.light}
            onClick={handleClose}
            label={"Cancel"}
          />
          <CustomButton
            padding={"0 50px"}
            bgColor={theme.palette.primary.main}
            hover={theme.palette.primary.light}
            label="Add"
            onClick={handleAddUserToGroup}
            disabled={!selectedGroup}
          />
        </DialogActions>
      </CustomDialog>
      <CustomDialog
        title="Invite User"
        isOpen={inviteDialogOpen}
        handleToggle={handleInviteClose}
        submitBottomTitle="Invite"
        fullWidth
        noContent
      >
        <form onSubmit={handleSubmit(handleInviteUser)}>
          <DialogContent
            sx={{
              p: 0,
              paddingLeft: "48px",
              paddingRight: "48px",
            }}
          >
            <Typography variant="subtitle1" fontWeight="500">
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                />
              )}
            />
          </DialogContent>

          <DialogActions
            sx={{
              p: 0,
              paddingRight: "48px",
              paddingBottom: "40px",
              paddingTop: "40px",
            }}
          >
            <CustomButton
              padding={"0 50px"}
              color={theme.palette.primary.main}
              bgColor={theme.palette.background.default}
              hover={theme.palette.gray.light}
              onClick={handleInviteClose}
              label={"Cancel"}
            />
            <CustomButton
              padding={"0 50px"}
              bgColor={theme.palette.primary.main}
              hover={theme.palette.primary.light}
              label="Invite"
              onClick={handleSubmit}
              type="submit"
            />
          </DialogActions>
        </form>
      </CustomDialog>
    </>
  );
};

export default Users;
