import { useEffect } from 'react';
import { disconnectSocket, initializeSocket } from '../../redux/saga/socket';

const AppBoot = () => {
    useEffect(() => {
        initializeSocket();
        return () => {
            disconnectSocket();
        };
    }, []);
    return null
}

export default AppBoot
