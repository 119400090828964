import { ContentPasteOff } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertsActions } from '../../../redux/slice/alerts.slice';
import { analyticsActions } from '../../../redux/slice/analytics.slice';
import { uiActions } from '../../../redux/slice/ui.slice';
import { GET_THREATS } from '../../../redux/types/multi.types';
import GraphTitle from './GraphTitle';
import { anomalyRangeOptions } from './helper';
import ThreatDetectionChart from './ThreatDetectionChart';
import styles from '../../shared/Tables/MyTable/myTable.module.scss'


const ThreatDetection = () => {
    const dispatch = useDispatch();
    const [selectedRange, setSelectedRange] = useState('1year');
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);
    const threatsData = useSelector(analyticsActions.selectors.getThreats);


    useEffect(() => {
        setLoading(true);
        setNoData(false);

        dispatch({
            type: GET_THREATS,
            payload: { range: selectedRange },
        });
    }, [dispatch, selectedRange])


    useEffect(() => {
        if (threatsData) {
            const hasData = threatsData?.high?.length > 0 || threatsData?.medium?.length > 0 || threatsData?.low?.length > 0;

            if (hasData) {
                setLoading(false);
                setNoData(false);
            }
            else {
                const timeout = setTimeout(() => {
                    setNoData(true);
                    setLoading(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        }
    }, [threatsData]);

    const navigate = useNavigate();
    const handleClickChart = (level) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        switch (level) {
            case 'High Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel('High'));
                break;
            case 'Medium Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel('Medium'));
                break;
            case 'Low Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel('Low'));
                break;
            default:
            // Do nothing
        }
        navigate('/');
    };

    return (
        <MyCard height={450}>
            <Grid item xs={12}>
                <GraphTitle
                    setTime={setSelectedRange}
                    time={selectedRange}
                    setSelectedRange={setSelectedRange}
                    showFullScreenBtn={false}
                    title={'Threat Detection Over Time'}
                    menuOptions={anomalyRangeOptions}
                />
                {loading ? (
                    <GraphSkelton height={350} />
                ) : noData ? (
                    <Box className={styles.noData}><ContentPasteOff /> No Data Available</Box>

                ) : (
                    <ThreatDetectionChart
                        threatsData={threatsData}
                        handleClickChart={handleClickChart}
                    />
                )}
            </Grid>
        </MyCard>
    );
};

export default ThreatDetection;
