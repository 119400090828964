import { insertIdToArray } from "components/utils";
import { put, takeEvery } from "redux-saga/effects";
import { getAccessPrivilegeService, getAllAlertsService, getAnomaliesService, getHighRiskDevicesService, getMultiAlertsService, getMultiDashboardAnalyticsServ, getUserRolesService } from "../services/multi.service";
import { alertsActions } from "../slice/alerts.slice";
import { analyticsActions } from "../slice/analytics.slice";
import { applicationsActions } from "../slice/applications.slice";
import { groupActions } from "../slice/group.slice";
import { networksActions } from "../slice/networks.slice";
import { packagesActions } from "../slice/packages.slice";
import { portsActions } from "../slice/ports.slice";
import { processesActions } from "../slice/processes.slice";
import { systemActions } from "../slice/system.slice";
import { GET_ACCESS_PRIVILEGE, GET_ALL_ALERTS, GET_ANOMALIES, GET_HIGH_RISK_DEVICES, GET_MULTI_ALERTS, GET_MULTI_DASHBOARD_ANALYTICS, GET_THREATS, GET_USER_ROLES } from "../types/multi.types";


export function* getMultiDashboardAnalytics() {
    try {
        const response = yield getMultiDashboardAnalyticsServ();
        if (response) {
            yield put(applicationsActions.actions.setMultiApps(insertIdToArray(response?.applications)));
            yield put(applicationsActions.actions.setMultiCombinedApps(insertIdToArray(response?.combinedApps)));
            yield put(networksActions.actions.setMultiNetworks(insertIdToArray(response?.networks)));
            yield put(packagesActions.actions.setMultiPackages(insertIdToArray(response?.packages)));
            yield put(portsActions.actions.setMultiPorts(insertIdToArray(response?.ports)));
            yield put(processesActions.actions.setMultiPorcesses(insertIdToArray(response?.processes)));
        }
        else {
            yield put(applicationsActions.actions.setApplications([]));
            yield put(applicationsActions.actions.setCombineApps([]));
            yield put(networksActions.actions.setNetworks([]));
            yield put(packagesActions.actions.setPackages([]));
            yield put(portsActions.actions.setPorts([]));
            yield put(processesActions.actions.setProcesses([]));
            yield put(systemActions.actions.setSystem({}));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getAllAlerts() {
    try {
        const response = yield getAllAlertsService();
        if (response) {
            yield put(alertsActions.actions.setAllAlerts(response?.data));
        }
        else {
            yield put(alertsActions.actions.setAllAlerts([]));

        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getMultiAlerts() {
    try {
        const response = yield getMultiAlertsService();
        if (response) {
            yield put(alertsActions.actions.setMultiAlerts(insertIdToArray(response?.data)));
        }
        else {
            yield put(alertsActions.actions.setMultiAlerts([]));

        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getAccessPrivilege() {
    try {
        const response = yield getAccessPrivilegeService();
        if (response) {
            yield put(groupActions.setAccessPrivilege(response?.data));
        }
        else {
            yield put(groupActions.setAccessPrivilege({}));

        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getUserRolesSaga() {
    try {
        const response = yield getUserRolesService();
        if (response) {
            yield put(analyticsActions.actions.setUserRoles(response?.data));
        }
        else {
            yield put(analyticsActions.actions.setUserRoles({}));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getHighRiskDevicesSaga(action) {
    try {
        const response = yield getHighRiskDevicesService(action.payload);
        if (response) {
            yield put(analyticsActions.actions.setHighRiskDevices(response?.data));
        }
        else {
            yield put(analyticsActions.actions.setHighRiskDevices([]));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getAnomaliesSaga(action) {
    try {
        const response = yield getAnomaliesService(action.payload);
        if (response) {
            yield put(analyticsActions.actions.setAnomalies(response?.data));
        }
        else {
            yield put(analyticsActions.actions.setAnomalies([]));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getThreatsSaga(action) {
    try {
        const response = yield getAnomaliesService(action.payload);
        if (response) {
            yield put(analyticsActions.actions.setThreats(response?.data));
        }
        else {
            yield put(analyticsActions.actions.setThreats([]));
        }
    } catch (err) {
        console.log("err", err);
    }
}





export function* watchMultiAsync() {
    yield takeEvery(GET_MULTI_DASHBOARD_ANALYTICS, getMultiDashboardAnalytics);
    yield takeEvery(GET_ALL_ALERTS, getAllAlerts);
    yield takeEvery(GET_ACCESS_PRIVILEGE, getAccessPrivilege);
    yield takeEvery(GET_USER_ROLES, getUserRolesSaga);
    yield takeEvery(GET_HIGH_RISK_DEVICES, getHighRiskDevicesSaga);
    yield takeEvery(GET_ANOMALIES, getAnomaliesSaga);
    yield takeEvery(GET_THREATS, getThreatsSaga);
    yield takeEvery(GET_MULTI_ALERTS, getMultiAlerts);
}