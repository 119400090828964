import { createSelector, createSlice } from "@reduxjs/toolkit";
import { SUBSCRIPTION_STATUS } from "components/utils/enums";

const initialState = {
  currentUser: {},
  accessToken: "",
  auth: false,
  loginTab: 0,
  profileImageUploadStatus: "idle",
  signUpSuccess: false,
  userRole: "",
  subscriptionStatus: SUBSCRIPTION_STATUS.active,
  isSubscriptionExpired: false,
  walkthroughs: {}
};

const name = "auth";
const parentSelector = (state) => state?.[name];

const authSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    resetAuth: () => {
      return initialState
    },
    loginUserStatus: (state, action) => {
      return {
        ...state,
        auth: action.payload,
      };
    },
    setCurrentUserSlice: (state, action) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    setLoginTab: (state, action) => {
      return {
        ...state,
        loginTab: action.payload,
      };
    },
    uploadProfileImageRequest: (state, action) => {
      state.profileImageUploadStatus = "loading";
    },
    uploadProfileImageSuccess: (state, action) => {
      state.profileImageUploadStatus = "succeeded";
      state.currentUser.profileImage = action.payload;
    },
    uploadProfileImageFailure: (state, action) => {
      state.profileImageUploadStatus = "failed";
    },
    resetProfileImageUploadStatus: (state, action) => {
      state.profileImageUploadStatus = "idle";
    },
    updateUserRequest: (state, action) => {
      state.userUpdateStatus = "loading";
    },
    updateUserSuccess: (state, action) => {
      state.userUpdateStatus = "succeeded";
      state.currentUser = { ...state.currentUser, ...action.payload };
    },
    updateUserFailure: (state, action) => {
      state.userUpdateStatus = "failed";
    },
    resetUserUpdateStatus: (state, action) => {
      state.userUpdateStatus = "idle";
    },
    deleteUserRequest: (state) => {
      state.deleteUserStatus = "loading";
    },
    deleteUserSuccess: (state) => {
      state.deleteUserStatus = "succeeded";
    },
    deleteUserFailure: (state) => {
      state.deleteUserStatus = "failed";
    },
    resetDeleteUserStatus: (state) => {
      state.deleteUserStatus = "idle";
    },
    setSignUpSuccessSlice: (state, action) => {
      return {
        ...state,
        signUpSuccess: action.payload,
      };
    },
    setUserRoleSlice: (state, action) => {
      return {
        ...state,
        userRole: action.payload,
      };
    },
    setSubscriptionStatus: (state, action) => {
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    },
    setIsSubscriptionExpired: (state, action) => {
      return {
        ...state,
        isSubscriptionExpired: action.payload,
      };
    },
    setWalkthroughs: (state, action) => {
      return {
        ...state,
        walkthroughs: action.payload,
      };
    },

  },
});

const getCurrentUser = createSelector(parentSelector, (state) => {
  return state?.currentUser;
});
const getLoggedInStatus = createSelector(parentSelector, (state) => {
  return state?.auth;
});
const getLoginTab = createSelector(parentSelector, (state) => {
  return state?.loginTab;
});

const getProfileImageUploadStatus = createSelector(parentSelector, (state) => {
  return state?.profileImageUploadStatus;
});

const getUserUpdateStatus = createSelector(parentSelector, (state) => {
  return state?.userUpdateStatus;
});

const getSignUpSuccess = createSelector(parentSelector, (state) => {
  return state?.signUpSuccess;
});

const getUserRole = createSelector(parentSelector, (state) => {
  return state?.userRole;
});

const getSubscriptionStatus = createSelector(parentSelector, (state) => {
  return state?.subscriptionStatus;
});

const getIsSubscriptionExpired = createSelector(parentSelector, (state) => {
  return state?.isSubscriptionExpired;
});

const getWalkthroughs = createSelector(parentSelector, (state) => {
  return state?.walkthroughs;
});

const actions = {
  ...authSlice.actions,
};

const selectors = {
  getLoggedInStatus,
  getCurrentUser,
  getLoginTab,
  getProfileImageUploadStatus,
  getUserUpdateStatus,
  getSignUpSuccess,
  getUserRole,
  getSubscriptionStatus,
  getIsSubscriptionExpired,
  getWalkthroughs
};

export const authActions = { actions, selectors };

export default authSlice;
