import React, { memo, useMemo } from 'react'
import MyCard from '../../shared/Card/Card'
import text from '../../shared/css/text.module.scss';
import SystemInfoCard from './SystemInfoCard';
import BatteryIcon from '../../../static/svgs/Battery';
import RamIcon from '../../../static/svgs/Ram';
import ProcessesIcon from '../../../static/svgs/Processes';
import IPIcon from '../../../static/svgs/IP';
import CpuIcon from '../../../static/svgs/CPU';
import PackagesIcon from '../../../static/svgs/Packages';
import OSIcon from '../../../static/svgs/OperatingSystem';
import UsersIcon from '../../../static/svgs/users';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { systemActions } from '../../../redux/slice/system.slice';
import { applicationsActions } from '../../../redux/slice/applications.slice';
import { devicesActions } from '../../../redux/slice/devices.slice';
import { processesActions } from '../../../redux/slice/processes.slice';
import { packagesActions } from '../../../redux/slice/packages.slice';
import { deviceUsersActions } from '../../../redux/slice/deviceUsers.slice';

const SystemInfo = () => {
    const systemInfo = useSelector(systemActions.selectors.getSystem);
    const currentDevice = useSelector(devicesActions.selectors.getCurrentDevice);
    const processes = useSelector(processesActions.selectors.getProcesses)
    const packages = useSelector(packagesActions.selectors.getPackages)
    const combineApps = useSelector(applicationsActions.selectors.getCombineApps);
    const devicesUsers = useSelector(deviceUsersActions.selectors.getdeviceUsers)

    const systemInfoCards = useMemo(() => {
        return [
            { icon: <BatteryIcon />, title: 'Battery', value: systemInfo?.bettery, symbol: '%' },
            { icon: <RamIcon />, title: 'Ram', value: systemInfo?.ramUsage, symbol: '%' },
            { icon: <ProcessesIcon />, title: 'Processes', value: processes?.length, symbol: '' },
            { icon: <IPIcon />, title: 'IP', value: currentDevice?.systemDetails?.ip, symbol: '' },
            { icon: <CpuIcon />, title: 'CPU', value: systemInfo?.cpuUsage?.toFixed(2), symbol: '%' },
            { icon: <PackagesIcon />, title: 'Package', value: combineApps?.length, symbol: '' },
            { icon: <OSIcon />, title: 'System', value: packages?.length, symbol: '' },
            { icon: <UsersIcon />, title: 'Users', value: devicesUsers?.length, symbol: '' },
        ]
    }, [systemInfo, currentDevice, processes?.length, packages?.length, combineApps?.length, devicesUsers?.length]);
    return (
        <MyCard padding={2}>
            <p className={text.heading4}>System Details</p>
            <Grid my={'15px'} overflow={'auto'} container spacing={2}>
                {systemInfoCards?.map((item, idx) => (
                    <Grid key={idx} item xs={6} md={4} lg={3}>
                        <SystemInfoCard key={idx} icon={item?.icon} title={item?.title} value={item?.value} symbol={item?.symbol} />
                    </Grid>
                ))}
            </Grid>
        </MyCard>
    )
}

export default memo(SystemInfo)
