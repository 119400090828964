import { capitalize } from "@mui/material";
import theme from "../../utils/theme"
import dayjs from "dayjs";
import MyBadge from "../../shared/MyBadge";

export const fimNavItems = [
    { label: 'File Integrity' },
    { label: 'Events' }
]



export const getFileDetailItems = (eventCounts) => {
    return (
        [
            { title: 'Created', value: eventCounts?.created || 0, color: theme.palette.success.main },
            { title: 'Deleted', value: eventCounts?.deleted || 0, color: theme.palette.error.main },
            { title: 'Modified', value: eventCounts?.modified || 0, color: theme.palette.warning.main },
            { title: 'Moved', value: eventCounts?.moved || 0, color: theme.palette.info.main },
        ]
    )
}

export const fimIntegrityColumns = [
    {
        field: 'timestamp',
        headerName: 'Date',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
            return (
                <p>{dayjs(params?.row?.timestamp).format('D[th] MMMM YYYY')}</p>
            )
        },
    },
    {
        field: 'timestsamp',
        headerName: 'Time',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
            return (
                <p>{dayjs(params?.row?.timestamp).format('hh:mm:ss a')}</p>
            )
        },
    },
    {
        field: 'path',
        headerName: 'Path',
        minWidth: 350,
        flex: 1,
    },
    {
        field: 'size',
        headerName: 'Event Type',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => getEventTypeBadge(capitalize(params?.row?.event))

    },
    {
        field: 'isDirectory',
        headerName: 'Type',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => <p>{params?.row?.isDirectory ? 'Folder' : 'File'}</p>
    },
];

export const FIM_DOWNLOAD_COLS = [
    { field: "timestamp", headerName: "Date & Time", type: "date" },
    { field: "path", headerName: "Path" },
    { field: "event", headerName: "Event Type" },
];



export const getEventTypeBadge = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'Created':
            return <MyBadge title={name} bgColor={theme.palette.success.main} txtColor={theme.palette.white.main} />;
        case 'Modified':
            return <MyBadge title={name} bgColor={theme.palette.warning.main} txtColor={theme.palette.white.main} />;
        case 'Deleted':
            return <MyBadge title={name} bgColor={theme.palette.error.main} txtColor={theme.palette.white.main} />;
        case 'Moved':
            return <MyBadge title={name} bgColor={theme.palette.info.main} txtColor={theme.palette.white.main} />;
    }
}