import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Stack, Typography } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import theme from 'components/utils/theme';
import styles from './OnPremPricing.module.scss';

const OnPremPricingCard = ({ plan, handleSubmitPlan, isNegotiable }) => {
    return (
        <Card className={styles.priceCard}>
            <Typography textAlign={"center"} className={styles.priceCardTitle}>{plan?.name}</Typography>

            <Typography className={styles.priceCardPrice}>
                <span>
                    <span className={styles.priceCardCurrency}>$</span>
                    <span className={styles.priceCardAmount}>{plan?.oneTimeLicenseFee}</span>
                </span>
                <span className={styles.priceCardDuration}> (One-Time License Fee)</span>
            </Typography>

            <Typography className={styles.priceCardPrice}>
                <span>
                    {
                        isNegotiable ?
                            <span className={styles.priceCardAmount}>Negotiable</span> :
                            <span className={styles.priceCardAmount}>{plan?.annualSupport}</span>
                    }
                </span>
                <span className={styles.priceCardDuration}>(Annual Support Fee)</span>
            </Typography>



            <Typography className={styles.priceCardFeaturesHeading}>What’s included</Typography>

            <Stack spacing={1} className={styles.priceCardFeatureList}>
                <Stack direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                    <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                    <Typography>No. of Users: {plan?.userLimit}</Typography>
                </Stack>
                <Stack direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                    <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                    <Typography>No. of Devices: {plan?.deviceLimit}</Typography>
                </Stack>
                {plan?.features.map((feature, index) => (
                    <Stack key={index} direction="row" alignItems="start" className={styles.priceCardFeatureItem}>
                        <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                        <Typography>{feature}</Typography>
                    </Stack>
                ))}
            </Stack>

            <CustomButton
                padding={"0px 50px"}
                fullWidth={true}
                color={theme.palette.background.default}
                bgColor={theme.palette.primary.main}
                hover={theme.palette.info.main}
                label={"Contact Sales"}
                type={"submit"}
                height="35px"
                onClick={() => handleSubmitPlan(plan)}
            />
        </Card>
    );
};

export default OnPremPricingCard;
