import { Card, Typography } from '@mui/material';
import { formatDateToReadable } from 'components/utils';
import styles from './subscription.module.scss';

const SubscriptionDetailsCard = ({ paymentMethod }) => {
    return (
        <Card className={styles.subscriptionDetailCard}>
            <span className={styles.memberSince}  >
                Member Since {formatDateToReadable(paymentMethod?.memberSince)}
            </span>
            <br />
            <div className={styles.nextPaymentContainer}>
                {!paymentMethod?.endsAt ?
                    <div>
                        <Typography variant="body1" fontWeight={'bold'}>Next Payment</Typography>
                        <Typography variant="body1" >You will be charged <span className={styles.paymentInfo}>{paymentMethod?.nextPaymentAmount} {paymentMethod?.currency}</span> on <span className={styles.paymentInfo}> {formatDateToReadable(paymentMethod?.nextPaymentDate)}</span> </Typography>
                    </div>
                    :
                    <div>
                        <Typography variant="body1" >Your Subscription will be ending on <span className={styles.paymentInfo}> {formatDateToReadable(paymentMethod?.endsAt)}</span> </Typography>
                    </div>
                }

                <div className={styles.buttonContainer}>
                    {/* <CustomButton
                        padding={"0px 30px"}
                        color={theme.palette.text.primary}
                        bgColor={theme.palette.gray.bg}
                        hover={theme.palette.gray.light}
                        label={"Renew Plan"}
                        onClick={handleRenewPlan}
                        type={"text"}
                    /> */}
                </div>

            </div>
        </Card>
    )
}

export default SubscriptionDetailsCard
