import React from "react";
import { Box, Grid } from "@mui/material";
import Profile from "components/modules/Account/Profile/Profile";
import UserForm from "components/modules/Account/UserForm/UserForm";
import PasswordReset from "components/modules/Account/PasswordReset/PasswordReset";
import UserInfo from "../UserInfo/UserInfo";
import { capitalizeFirstLetter } from "components/utils/accounts";
import styles from "./ProfileTab.module.scss";

const ProfileTab = ({ currentUser, month, day, year }) => {
  return (
    <Box className={styles.profileTab}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Profile
              name={currentUser?.username || "Unknown"}
              accountType={capitalizeFirstLetter(currentUser?.role) || "User"}
              imageUrl={currentUser?.profileImage}
              orgId={currentUser?.orgId}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <UserForm
              email={currentUser.email}
              gender={currentUser.gender}
              username={currentUser.username}
            />
          </Box>
          <Box>
            <PasswordReset email={currentUser.email} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <UserInfo
              month={month}
              day={day}
              year={year}
              country={currentUser.country}
              userId={currentUser._id}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileTab;
