import CustomWalkthrough from "components/modules/Walkthrough/CustomWalkthrough";
import { useDispatch, useSelector } from "react-redux";
import Device from "../components/modules/Device/Device";
import { authActions } from "../redux/slice/auth.slice";
import { walkthroughsContent } from "components/modules/Walkthrough/helper";
import { UPDATE_USER_SETTINGS } from "../redux/types/auth.types";

const Devices = () => {

  const dispatch = useDispatch()
  const walkthroughsBool = useSelector(authActions.selectors.getWalkthroughs);

  const handleSkipWalkthrough = () => {
    const payload = {
      settings: {
        walkthroughs: {
          ...walkthroughsBool,
          deviceWalkthrough: false
        }
      }
    }
    dispatch({ type: UPDATE_USER_SETTINGS, payload })
  }
  return (<>
    <CustomWalkthrough onSkip={handleSkipWalkthrough} run={walkthroughsBool?.deviceWalkthrough} walkthrough={walkthroughsContent.deviceWalkthrough} />
    <Device />
  </>)
};

export default Devices;
