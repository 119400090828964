import React, { useEffect, useMemo, useState } from 'react';
import MyCard from 'components/shared/Card/Card';
import GraphTitle from './GraphTitle';
import ReactApexChart from "react-apexcharts";
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';

const PrivilegeAccess = ({ accessPrivilege = { Least: 0, Moderate: 0, Excessive: 0 } }) => {
    const [loading, setLoading] = useState(true);
    const [showNoData, setShowNoData] = useState(false);

    useEffect(() => {
        const total = accessPrivilege?.Least + accessPrivilege?.Moderate + accessPrivilege?.Excessive;
        if (total > 0) {
            setLoading(false);
            setShowNoData(false);
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
                setShowNoData(true);
            }, 2000); // Show "No Data" after 2 seconds if data is still not available
            return () => clearTimeout(timer);
        }
    }, [accessPrivilege]);

    const series = useMemo(() => [
        {
            name: "Number of Users",
            data: [
                accessPrivilege?.Least || 0,
                accessPrivilege?.Moderate || 0,
                accessPrivilege?.Excessive || 0
            ],
        },
    ], [accessPrivilege]);

    const options = {
        chart: {
            type: 'bar',
            height: 250,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'flat',
            },
        },
        grid: {
            borderColor: '#E0E0E0',
            strokeDashArray: 4,
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: true } }
        },
        colors: ['#00A307', '#FFA500', '#FF0000'],
        dataLabels: { enabled: false },
        xaxis: {
            categories: ['Least Privilege', 'Moderate Privilege', 'Excessive Privilege'],
            labels: { style: { fontSize: '14px' } },
        },
        yaxis: {
            title: {
                text: 'Number of Users',
                style: { fontSize: '14px', fontWeight: 'bold' }
            },
            labels: { style: { fontSize: '14px' } },
        },
        legend: { show: false },
        tooltip: {
            enabled: true,
            y: { formatter: (val) => `${val}` },
        },
    };

    return (
        <MyCard>
            <GraphTitle showTimeMenu={false} title={'Privilege Access Monitoring'} />
            {loading ? (
                <GraphSkelton height={280} />
            ) : showNoData ? (
                <div className={tableStyles.noData}>
                    <ContentPasteOffIcon /> No Data Available
                </div>
            ) : (
                <ReactApexChart options={options} series={series} type="bar" height={250} />
            )}
        </MyCard>
    );
}

export default PrivilegeAccess;
