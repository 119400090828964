export const dummyOnPremisesData = [
    {
        "name": "Basic On-Prem",
        "oneTimeLicenseFee": "10,000",
        "annualSupport": "2,500/y",
        "userLimit": 10,
        "deviceLimit": 20,
        "features": [
            "Core security layers",
            "Machine fingerprinting",
            "Endpoint security"
        ]
    },
    {
        "name": "Professional On-Prem",
        "oneTimeLicenseFee": "40,000",
        "annualSupport": "10,000/y",
        "userLimit": 100,
        "deviceLimit": 250,
        "features": [
            "SIEM integration",
            "Compliance enforcement",
            "Advanced threat detection"
        ]
    },
    {
        "name": "Enterprise On-Prem",
        "oneTimeLicenseFee": "100,000+",
        "annualSupport": "25,000+/y",
        "userLimit": "Unlimited",
        "deviceLimit": 1000,
        "features": [
            "Full Zero Trust security",
            "OT/IT/IoT protection",
            "AI-based security analytics"
        ]
    },
    {
        "name": "Custom Deployment",
        "oneTimeLicenseFee": "250,000+",
        "annualSupport": "Negotiable",
        "userLimit": "Unlimited",
        "deviceLimit": "Unlimited",
        "features": [
            "Hybrid solutions",
            "Dedicated on-prem hardware",
            "Air-gapped security"
        ]
    }
];
