import { Grid, Typography } from '@mui/material'
import CustomButton from 'components/shared/Buttons/CustomButton'
import MyCard from 'components/shared/Card/Card'
import CustomTextField from 'components/shared/Forms/CustomTextField'
import theme from 'components/utils/theme'
import { useForm } from 'react-hook-form'
import { object } from 'yup'
import { formResetPasswordSchema, YupResetPasswordSchema } from './helper'
const DeviceAdministration = () => {

    let deviceUserSchema = object(YupResetPasswordSchema);
    const { control, handleSubmit, formState: { errors } } = useForm(formResetPasswordSchema(deviceUserSchema))

    const handleResetPasswordSubmit = (payload) => {
    };

    return (
        <>
            <MyCard height='300px' padding={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Reset your password</Typography>
                    <Typography variant='subtitle2'>
                        Secure your account by updating your password
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(handleResetPasswordSubmit)} >
                    <Grid mt={2} container spacing={2}>
                        <Grid item xs={6}>
                            <CustomTextField
                                control={control}
                                errors={errors}
                                name={'password'}
                                type="password"
                                label="New Password"
                                placeHolder={'*******'}
                                borderRadius={"10px"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTextField
                                control={control}
                                errors={errors}
                                name={'confirmPassword'}
                                type="password"
                                label="Confirm Password"
                                placeHolder={'*******'}
                                borderRadius={"10px"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item mt={2} spacing={2} xs={12} container >
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item xs={2}>
                            <CustomButton fullWidth={true} color={'#000'} bgColor={theme.palette.gray.bg} hover={theme.palette.gray.light} label={"Cancel"} />
                        </Grid>
                        <Grid item xs={2}>
                            <CustomButton type={"submit"} fullWidth={true} bgColor={theme.palette.primary.main} hover={theme.palette.primary.light} label={"Save"} />
                        </Grid>
                    </Grid>
                </form>

            </MyCard>
            <br />
            <MyCard height={'100px'} padding={3}>
                <Grid container alignItems={'center'}>
                    <Grid item xs={8}>
                        <Typography variant='h6'>Delete User</Typography>
                        <Typography variant='subtitle2'>
                            This action is permanent and cannot be undone. Deleting this user will remove all associated data.
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <CustomButton fullWidth={true} bgColor={theme.palette.error.main} hover={theme.palette.primary.main} label={"Delete"} />
                    </Grid>
                </Grid>
            </MyCard>
        </>
    )
}

export default DeviceAdministration
