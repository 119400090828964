import { call, put, takeEvery } from "redux-saga/effects";
import { fetchAlertPoliciesService, createAlertPolicyService, assignAlertPolicyToGroupService } from "../services/alertPolicy.service";
import { alertPolicyActions } from "../slice/alertPolicy.slice";
import { FETCH_ALERT_POLICIES, CREATE_ALERT_POLICY, ASSIGN_ALERT_POLICY_TO_GROUP } from "../types/alertPolicy.types";
import toast from "react-hot-toast";

function* fetchAlertPoliciesSaga(action) {
  try {
    const alertPolicies = yield call(fetchAlertPoliciesService, action.payload);
    yield put(alertPolicyActions.actions.fetchAlertPoliciesSuccess(alertPolicies));
  } catch (error) {
    yield put(alertPolicyActions.actions.fetchAlertPoliciesFailure(error.message));
  }
}

function* createAlertPolicySaga(action) {
  try {
    const alertPolicy = yield call(createAlertPolicyService, action.payload);
    yield put(alertPolicyActions.actions.createAlertPolicySuccess(alertPolicy));
  } catch (error) {
    yield put(alertPolicyActions.actions.createAlertPolicyFailure(error.message));
  }
}

function* assignPolicyToGroupSaga(action) {
  try {
    yield call(assignAlertPolicyToGroupService, action.payload)
    toast.success("Policy Assigned to group");
  } catch (error) {
    toast.error("Failed to assign policies to group")
  }
}

export function* watchAlertPolicyAsync() {
  yield takeEvery(FETCH_ALERT_POLICIES, fetchAlertPoliciesSaga);
  yield takeEvery(CREATE_ALERT_POLICY, createAlertPolicySaga);
  yield takeEvery(ASSIGN_ALERT_POLICY_TO_GROUP, assignPolicyToGroupSaga)
}
