import { Box, Skeleton } from '@mui/material'
import React from 'react'

const GraphSkelton = ({ height = 300, bars = 10 }) => {
    return (
        <Box overflow={"hidden"} height={height} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Box display={'flex'} alignItems={"flex-end"} flexDirection={'row'} gap={1}>
                {[...Array(bars)].map((_, index) => (
                    <>
                        <Skeleton key={index} variant="rectangular" width={40} height={(index * 20) + (height - 220)} />
                    </>
                ))}
            </Box>
        </Box>
    )
}

export default GraphSkelton
