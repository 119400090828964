import { Box, Grid } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import GraphTitle from './GraphTitle';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uiActions } from '../../../redux/slice/ui.slice';
import { alertsActions } from '../../../redux/slice/alerts.slice';
import theme from 'components/utils/theme';
import GraphSkelton from 'components/shared/Skeletons/GraphSkelton';

const SecurityAlerts = ({ alerts }) => {
    const [loading, setLoading] = useState(true);
    const [showNoData, setShowNoData] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (alerts?.types?.length) {
            setLoading(false);
            setShowNoData(false);
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
                setShowNoData(true);
            }, 2000); // Show "No Data" after 2 seconds if data is still not available
            return () => clearTimeout(timer);
        }
    }, [alerts]);

    const handleClickBar = (type, level) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        dispatch(alertsActions.actions.setSelectedLevel(level));
        navigate("/");
    };

    const options = useMemo(() => {
        if (alerts?.types?.length) {
            return {
                dataLabels: { enabled: false },
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: { show: false },
                    events: {
                        dataPointSelection: (event, chartContext, { dataPointIndex, seriesIndex }) => {
                            const alertType = alerts?.types?.[dataPointIndex];
                            const alertLevel = alerts?.chartData?.[seriesIndex]?.name;
                            handleClickBar(alertType, alertLevel);
                        }
                    }
                },
                colors: [theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main],
                plotOptions: { bar: { borderRadius: 5, columnWidth: '40px' } },
                grid: {
                    show: true,
                    borderColor: '#e0e0e0',
                    strokeDashArray: 4,
                    position: 'back',
                    xaxis: { lines: { show: false } },
                    yaxis: { lines: { show: true } }
                },
                xaxis: {
                    categories: alerts?.types,
                    axisBorder: { show: false },
                    axisTicks: { show: true, color: '#808080' },
                    title: {
                        text: 'Categories',
                        style: {
                            color: '#808080',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold'
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: { formatter: (val) => Math.floor(val) },
                    title: {
                        text: 'No of Alerts',
                        style: {
                            color: '#808080',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold'
                        }
                    }
                },
                fill: { opacity: 1 },
                legend: { show: false }
            };
        }
        return {};
    }, [alerts]);

    const handleToggleScreen = () => {
        setIsFullScreen((prevState) => !prevState);
    };

    return (
        <>
            <MyCard height={350}>
                <GraphTitle
                    showTimeMenu={false}
                    showFullScreenBtn={true}
                    handleClickFullScreen={handleToggleScreen}
                    title={'Security Alerts Overview'}
                />
                {loading ? (
                    <GraphSkelton height={300} />
                ) : showNoData ? (
                    <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Data Available</Box>
                ) : (
                    <Chart options={options} series={alerts.chartData} type="bar" height={280} />
                )}
            </MyCard>

            <CustomDialog fullScreen isOpen={isFullScreen} handleToggle={handleToggleScreen} title="Security Alerts Overview" maxWidth="xl">
                <Grid height="100%">
                    {loading ? (
                        <GraphSkelton height="100%" />
                    ) : showNoData ? (
                        <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Data Available</Box>
                    ) : (
                        <Chart options={options} series={alerts.chartData} type="bar" height="100%" />
                    )}
                </Grid>
            </CustomDialog>
        </>
    );
};

export default SecurityAlerts;
