import NetworkKeyFeatures from "components/modules/Networks/NetworkKeyFeatures/NetworkKeyFeatures";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../redux/slice/ui.slice";
import { UPDATE_USER_SETTINGS } from "../redux/types/auth.types";
import { authActions } from "../redux/slice/auth.slice";
import CustomWalkthrough from "components/modules/Walkthrough/CustomWalkthrough";
import { walkthroughsContent } from "components/modules/Walkthrough/helper";

const Networks = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(uiActions.actions.setSelectedNav("Networks"));
  }, [dispatch])

  const walkthroughsBool = useSelector(authActions.selectors.getWalkthroughs);
  const handleSkipWalkthrough = () => {
    const payload = {
      settings: {
        walkthroughs: {
          ...walkthroughsBool,
          networkWalkthrough: false
        }
      }
    }
    dispatch({ type: UPDATE_USER_SETTINGS, payload })
  }

  return (
    <>
      <CustomWalkthrough onSkip={handleSkipWalkthrough} run={walkthroughsBool?.networkWalkthrough} walkthrough={walkthroughsContent.networkWalkthrough} />

      <NetworkKeyFeatures />
    </>
  );
};

export default Networks;
