import Joyride from "react-joyride";

const CustomWalkthrough = ({ run, walkthrough, onSkip }) => {
    return (
        <Joyride
            run={run}
            continuous={true}
            showProgress={true}
            showSkipButton={false}
            disableOverlayClose={true}
            locale={{ last: "Close" }}
            steps={walkthrough?.steps}
            callback={(data) => {
                if (data?.action === "reset") {
                    onSkip?.();
                }
            }}

        />
    );
};

export default CustomWalkthrough;
