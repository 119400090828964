import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import theme from "components/utils/theme";
import CloseIcon from "static/svgs/CloseIcon";
import text from "../../shared/css/text.module.scss";
import CustomButton from "../Buttons/CustomButton";
import styles from "./dialog.module.scss";

const CustomDialog = ({
  title,
  isOpen,
  handleToggle,
  handleSubmit,
  submitBottomTitle = "Save",
  children,
  maxWidth,
  borderRadius = "31px",
  fullScreen = false,
  action = false,
  noContent = false,
  fullWidth,
  showTitle = true
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          borderRadius: borderRadius,
          overflow: "none",
          background: theme.palette.white.main,
        },
      }}
    >
      {showTitle &&
        <Box
          sx={{
            p: 0,
            paddingTop: "30px",
            paddingX: "38px",
            marginBottom: "30px",
          }}
        >
          <div className={styles.dialogTitle}>
            <DialogTitle className={text.heading1} sx={{ p: 0 }}>
              {title}
            </DialogTitle>
            <IconButton
              onClick={handleToggle}
              className={styles.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      }
      {noContent ? (
        children
      ) : (
        <DialogContent
          className={styles.dialogueContent}
          sx={{
            p: 0,
            paddingLeft: "48px",
            paddingRight: "48px",
            // paddingBottom: "40px",
          }}
        >
          {children}
        </DialogContent>
      )}
      {action && (
        <DialogActions
          sx={{
            p: 0,
            paddingRight: "48px",
            paddingBottom: "40px",
            paddingTop: "40px",
          }}
        >
          <CustomButton
            padding={"0 50px"}
            color={theme.palette.primary.main}
            bgColor={theme.palette.background.default}
            hover={theme.palette.gray.light}
            onClick={handleToggle}
            label={"Cancel"}
          />
          <CustomButton
            padding={"0 50px"}
            bgColor={theme.palette.primary.main}
            hover={theme.palette.primary.light}
            label={submitBottomTitle}
            onClick={handleSubmit}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
