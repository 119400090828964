import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import text from "../../shared/css/text.module.scss";
import AppCard from "./AppCard";
import styles from "./apps.module.scss";
import { getAppInstalledItems, getUserAppItems } from "./helper";
import { OS } from "components/utils/enums";

const TotalApps = ({ os, applications, packages }) => {
  const appInstalledItems = useMemo(() => {
    return getAppInstalledItems(applications?.length);
  }, [applications?.length]);

  const userAppItems = useMemo(() => {
    return getUserAppItems(packages?.length);
  }, [packages?.length]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12} className={styles.appCardContainer} container>
        <p className={text.heading1}>Total App Installed</p>
        <div className={styles.innerContainer}>
          {appInstalledItems?.map((item, idx) => (
            <AppCard {...item} key={idx} />
          ))}
        </div>
      </Grid>
      {os !== OS.ubuntu &&
        <Grid item lg={6} md={12} className={styles.appCardContainer} container>
          <p className={text.heading1}>Total System Apps</p>
          <div className={styles.innerContainer}>
            {userAppItems?.map((item, idx) => (
              <AppCard {...item} key={idx} />
            ))}
          </div>
        </Grid>
      }
    </Grid>
  );
};

export default TotalApps;
