import { ArrowBack } from '@mui/icons-material';
import { Card, Grid, IconButton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '../../../redux/slice/subscription.slice';
import CurrentPlanCard from './CurrentPlanCard';
import PaymentForm from './PaymentForm';
import styles from './pricingPlan.module.scss';
import { upgradeButtonDetails } from './helper';
const BillingBody = ({ selectedPlan, isYearly, selectedTab }) => {
    const dispatch = useDispatch()

    const subscriptionDiscount = useSelector(subscriptionActions.selectors.getSubscriptionDiscount)
    const isTrailAvailable = useSelector(subscriptionActions.selectors.getIsTrailAvailable)

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const handlePreviousTab = () => {
        if (selectedTab > 0) {
            dispatch(subscriptionActions.actions.setSelectedPricingTab(selectedTab - 1))
        }
    }



    return (
        <Grid container justifyContent={'center'}>
            <Grid xs={10} >
                {selectedTab > 0 &&
                    <div className={styles.navigationButton}>
                        <IconButton onClick={handlePreviousTab}>
                            <ArrowBack />
                        </IconButton>
                    </div>
                }
                <CurrentPlanCard isFreeTrial={isTrailAvailable && selectedPlan?.name === "BASIC"} discountPercent={subscriptionDiscount?.discountPercentOff} title={"Subscription Plan"} handleClickButton={handlePreviousTab} isYearly={isYearly} selectedPlan={selectedPlan} buttonDetails={upgradeButtonDetails} />
                <br />
                <Card className={styles.subscriptionPlanCard}>
                    <Elements stripe={stripePromise}>
                        <PaymentForm selectedPlan={selectedPlan} isYearly={isYearly} />
                    </Elements>
                </Card>
            </Grid>

        </Grid>
    )
}

export default BillingBody
