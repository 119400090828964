import Alerts from "components/modules/SingleDashboard/Alerts";
import DeviceUsers from "components/modules/SingleDashboard/DeviceUsers";
import { checkUserDeviceUserPermissions } from "components/utils/accessManagement";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppsContainer from "../components/modules/Apps/AppsContainer";
import Fim from "../components/modules/Fim/Fim";
import SingleDashboard from "../components/modules/SingleDashboard/SingleDashboard";
import CustomNavTabs from "../components/shared/CustomNavTabs";
import { dashboardNavItems } from "../components/utils";
import { applicationsActions } from "../redux/slice/applications.slice";
import { authActions } from "../redux/slice/auth.slice";
import { packagesActions } from "../redux/slice/packages.slice";
import { uiActions } from "../redux/slice/ui.slice";
import { FETCH_GROUPS } from "../redux/types/group.types";

const SingleDashboardPage = () => {
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_GROUPS });
    dispatch(uiActions.actions.setSelectedNav(""));
  }, [dispatch, id, currentUser]);


  const groups = useSelector((state) => state.group.groups);

  const userPermissions = checkUserDeviceUserPermissions(currentUser?._id, id, groups, currentUser?.role);
  const NavItems = dashboardNavItems;

  const applications = useSelector(
    applicationsActions.selectors.getApplications
  );
  const packages = useSelector(packagesActions.selectors.getPackages);



  const handleComponents = (tab) => {
    // eslint-disable-next-line default-case
    switch (tab) {
      case 0:
        return <SingleDashboard />;
      case 1:
        return <AppsContainer applications={applications} packages={packages} />
      case 2:
        return <Fim />;
      case 3:
        return <Alerts />;
      case 4:
        return <DeviceUsers userPermissions={userPermissions} />;
      default:
        <SingleDashboard />
    }
  };

  return (
    <div>
      <CustomNavTabs
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        navItems={NavItems}
      />
      {handleComponents(selectedTab)}
    </div>
  );
};

export default SingleDashboardPage;
