import { Delete } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import MyBadge from "components/shared/MyBadge";
import tables from "components/shared/Tables/table.module.scss";
import theme from "components/utils/theme";


export const permissionGroups = [
    {
        name: 'Identity and Access Management (IAM)',
        permissions: ['ADD_USER', 'DELETE_USER', 'VIEW_USER', 'ENFORCE_MFA']
    },
    {
        name: 'Network Access Control',
        permissions: ['ADD_NETWORK', 'DELETE_NETWORK', 'VIEW_NETWORK', 'MONITOR_NETWORK_TRAFFIC']
    },
    {
        name: 'Device and Endpoint Security',
        permissions: ['DISABLE_USB', 'CONTROL_REMOTE_ACCESS', "CONTINUOUS_AUTHENTICATION"]
    },
    {
        name: 'Application Security',
        permissions: ['DELETE_APPLICATION', 'VIEW_APPLICATION', 'WHITELIST_APPLICATION', 'BLACKLIST_APPLICATION']
    },
    {
        name: 'Incident Response and Monitoring',
        permissions: ['LOG_SECURITY_EVENTS', 'THREAT_DETECTION_POLICY', 'MANAGE_ALERTS']
    },
];


export const getAlertColumns = (handleDeleteAlertPolicyOpen) => {
    return [
        { field: "policyName", headerName: "Policy Name", flex: 1 },
        { field: "type", headerName: "Type", flex: 1 },
        { field: "osPlatform", headerName: "OS Platform", flex: 1 },
        {
            field: "details",
            headerName: "Details",
            minWidth: 100,
            flex: 3,
            renderCell: (params) => (
                <Box sx={{ maxWidth: "100%", overflowX: "auto", display: "flex" }}>
                    <Box sx={{ minWidth: "fit-content" }}>
                        {params.row.details.map((detail, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                className={tables.actionBtn}
                                size="small"
                                sx={{ marginRight: 1, whiteSpace: "nowrap" }}
                            >
                                {detail}
                            </Button>
                        ))}
                    </Box>
                </Box>
            ),
        },
        {
            field: "size",
            headerName: "Alert Level",
            flex: 1,
            renderCell: (params) => {
                return getAccessBadge(params.row.alertLevel);
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteAlertPolicyOpen(params.row._id);
                    }}
                >
                    <Delete />
                </IconButton>
            ),
        },
    ];
}

export const ALERT_POLICY_DOWNLOAD_COLS = [
    { field: "policyName", headerName: "Policy Name" },
    { field: "type", headerName: "Type" },
    { field: "osPlatform", headerName: "OS Platform" },
    { field: "alertLevel", headerName: "Alert Level" },
    { field: "details", headerName: "Details" },
];


export const getAccessBadge = (type) => {
    switch (type) {
        case 'High':
            return <MyBadge
                title={"High"}
                bgColor={theme.palette.error.main}
                txtColor={theme.palette.white.main}
            />
        case "Medium":
            return <MyBadge
                title={"Medium"}
                bgColor={theme.palette.warning.main}
                txtColor={theme.palette.white.main}
            />
        case "Low":
            return <MyBadge
                title={"Low"}
                bgColor={theme.palette.success.main}
                txtColor={theme.palette.white.main}
            />
        default:
        //someting
    }

};
