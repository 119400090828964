import { ACCESS_MANAGEMENT_TABS } from "components/utils/accessManagement";
import { computeTotalGroupsCount } from "components/utils/policy";
import theme from "components/utils/theme"
import PeopleIcon from "static/svgs/People"
import RegisterSettingsIcon from "static/svgs/RegisterSettings";
import UsersIcon from "static/svgs/users";


export const getPortalManagementResources = ({ groups, users, policies }) => {
    return [
        {
            count: computeTotalGroupsCount(groups),
            label: "User Group",
            icon: <PeopleIcon />,
            className: "user-group",
            tab: ACCESS_MANAGEMENT_TABS.GROUPS,
        },
        {
            count: users.length,
            label: "Users",
            icon: <UsersIcon />,
            className: "users",
            tab: ACCESS_MANAGEMENT_TABS.USER,
        },
        {
            count: policies?.length,
            label: "Roles",
            icon: <RegisterSettingsIcon />,
            className: "roles",
            tab: ACCESS_MANAGEMENT_TABS.POLICIES,
        },

    ]
}

export const portalCardColors = [
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.palette.info.main,
];

export const portalNavItems = [
    { label: ACCESS_MANAGEMENT_TABS.GROUPS },
    { label: ACCESS_MANAGEMENT_TABS.USER },
    { label: ACCESS_MANAGEMENT_TABS.ROLES },
]