import "@fontsource/poppins";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from "react-hot-toast";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import MyRouter from "./components/routes/MyRouter";
import AppBoot from "./components/shared/AppBoot";
import theme from "./components/utils/theme";
import { persistor, store } from "./redux/store";

const App = () => {

  console.log("process", process.env)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme} >
          <CssBaseline />
          <AppBoot />
          <MyRouter />
          <Toaster />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
